import React from "react";
import MvpDossierPage from "./MvpDossierPage/MvpDossierPage";
import EpcDossierPage from "./EpcDossierPage/EpcDossierPage";
import MvlDossierPage from "./MvlDossierPage/MvlDossierPage";
import DefaultDossierPage from "./DefaultDossierPage/DefaultDossierPage";

import {
  externalProps,
  internalProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import PropTypes from "prop-types";

const DossierPage = (props) => {
  switch (props.dossier.dossierDefinitionKey) {
    case "mijn_verbouwpremie":
      return <MvpDossierPage {...props} />;

    case "epc_labelPremie":
      return <EpcDossierPage {...props} />;

    case "mijn_verbouwlening":
      return <MvlDossierPage {...props} />;

    default:
      return <DefaultDossierPage {...props} />;
  }
};

export default DossierPage;

DossierPage.propTypes = {
  ...externalProps,
  ...internalProps,
  dossier: PropTypes.shape({
    ...externalProps.dossier,
    dossierDefinitionKey: PropTypes.string,
  }),
};
