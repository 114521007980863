import React, { useContext, useEffect, useState } from "react";
import { flatten, get, isBoolean, map } from "lodash";
import classnames from "classnames";

import { TranslationsContext } from "@skryv/core-react/src/services/translations";
import {
  errorMessages,
  generatePathStringForManipulator,
  warningMessages,
} from "@skryv/core-react/src/core/services/docmod/formRendererHelpers";

import Popover, {
  popoverPositions,
  closePopovers,
  popoverSizes,
} from "@skryv/core-react-vo/src/components/base/Popover/Popover";

import InputField from "@skryv/core-react-vo/src/components/base/InputFields/InputField/InputField";
import InlineErrors from "@skryv/core-react-vo/src/components/base/InputFields/InputField/inlines/InlineErrors/InlineErrors";
import InlineHelp from "@skryv/core-react-vo/src/components/base/InputFields/InputField/inlines/InlineHelp/InlineHelp";
import InputLabel from "@skryv/core-react-vo/src/components/base/InputFields/InputField/inlines/InputLabel/InputLabel";
import ListInput from "@skryv/core-react-vo/src/components/form/components/editor/ListInput/ListInput";
import EditorComponent from "@skryv/core-react-vo/src/components/form/components/wrappers/EditorComponent/EditorComponent";

import "./RoofParts.scss";

const COLUMN_WIDTHS = [
  "20%", // isolatiemateriaal
  "28%", // isolatiemerk
  "15%", // isolatietype
  "10%", // dikte
  "10%", // oppervlakte
  "10%", // rd-waarde
  "7%", // delete button
];
const MOBILE_WIDTH = 768;

function RoofParts({ component, manipulator, config, readOnly }) {
  const { gettext } = useContext(TranslationsContext);

  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth <= MOBILE_WIDTH
  );

  useEffect(() => {
    const updateIsMobileView = () => {
      setIsMobileView(window.innerWidth <= MOBILE_WIDTH);
    };
    window.addEventListener("resize", updateIsMobileView);
    return () => window.removeEventListener("resize", updateIsMobileView);
  }, []);

  const listManipulator = manipulator.propertyManipulators["lagen"];
  const listComponent = component.components[0];

  const listErrorMessages = errorMessages(
    listManipulator,
    config.currentDocument.getIsReviewing(),
    true
  );
  const listWarningMessages = warningMessages(
    listManipulator,
    config.currentDocument.getIsReviewing(),
    true
  );
  const listIsInError =
    (listErrorMessages ? listErrorMessages.length > 0 : false) ||
    (listWarningMessages ? listWarningMessages.length > 0 : false);
  const listManipulatorId = generatePathStringForManipulator(listManipulator);

  const totalManipulator = manipulator.propertyManipulators["totaalRdWaardes"];
  const totalComponent = component.components[1];

  const totalErrorMessages = errorMessages(
    totalManipulator,
    config.currentDocument.getIsReviewing(),
    true
  );
  const totalWarningMessages = warningMessages(
    totalManipulator,
    config.currentDocument.getIsReviewing(),
    true
  );
  const totalIsInError =
    (totalErrorMessages ? totalErrorMessages.length > 0 : false) ||
    (totalWarningMessages ? totalWarningMessages.length > 0 : false);

  function generateHeaders() {
    const firstListItem = get(listManipulator, ["elementManipulators", 0]);
    // filter out hidden components
    const components = get(
      listComponent,
      ["listElementComponent", "components"],
      []
    );
    return components.map((component, index) => {
      // the component contains a boolean or the name of the computed expression that defines the required property
      let required = component.inputOptions.required;
      // if the component contains the name of a computed expression
      // - we check the value of that computed expression for the first list item and use that value
      //   NOTE: in theory, it could be possible that the computed expression gives another value for other list items, but we assume that is not the case for now
      // - if no list items are present, we set 'required' to false
      if (!isBoolean(required)) {
        if (!firstListItem) required = false;
        else {
          required = firstListItem.computedExpressions[required];
        }
      }
      return {
        required,
        label: component.label,
        id: component.inputOptions.name,
        helpText: component.help,
        helpInLine: component.helpInLine,
        columnWidth: COLUMN_WIDTHS[index],
      };
    });
  }

  function generateElements() {
    return listManipulator.elementManipulators.map((elementManipulator) => ({
      id: generatePathStringForManipulator(elementManipulator),
      errorMessages: flatten(
        elementManipulator.nestedManipulators.map((nestedManipulator) => {
          const label = get(nestedManipulator, ["description", "label"]);
          const errors = errorMessages(
            nestedManipulator,
            config.currentDocument.getIsReviewing(),
            false
          );
          return label
            ? errors.map((errorMessage) => ({
                ...errorMessage,
                key: `${errorMessage.id}-${errorMessage.key}`,
                message: `${label}: ${gettext(errorMessage.message)}`,
              }))
            : errors;
        })
      ),
      warningMessages: flatten(
        elementManipulator.nestedManipulators.map((nestedManipulator) => {
          const label = get(nestedManipulator, ["description", "label"]);
          const warnings = warningMessages(
            nestedManipulator,
            config.currentDocument.getIsReviewing(),
            false
          );
          return label
            ? warnings.map((warningMessage) => ({
                ...warningMessage,
                key: `${warningMessage.id}-${warningMessage.key}`,
                message: `${label}: ${gettext(warningMessage.message)}`,
              }))
            : warnings;
        })
      ),
      renderElements: () =>
        elementManipulator.nestedManipulators.map(
          (nestedManipulator, index) => (
            <EditorComponent
              id={"id-" + generatePathStringForManipulator(nestedManipulator)}
              key={"id-" + generatePathStringForManipulator(nestedManipulator)}
              component={listComponent.listElementComponent.components[index]}
              manipulator={nestedManipulator}
              config={config}
              nested={true}
              readOnly={readOnly}
            />
          )
        ),
    }));
  }

  function deleteCandidate(itemIndex) {
    if (itemIndex !== undefined) {
      listManipulator.elementManipulators[itemIndex].deleteElement();
    } else {
      console.error(
        "Something went wrong while deleting an item from the list"
      );
    }
  }

  function addElement() {
    listManipulator.addElement();
  }

  function renderTable() {
    return (
      <table className="wrp-cc-roof-parts vl-data-table">
        <thead>
          <tr>
            {map(generateHeaders(), (field) => (
              <th
                key={field.id}
                id={`${listManipulatorId}-col-${field.id}`}
                style={{ width: field.columnWidth }}
              >
                <InputField
                  id={field.id}
                  label={field.label}
                  isRequired={field.required}
                  helpInLine={field.helpInLine}
                  help={field.helpText}
                  shouldUseFullWidth={true}
                />
              </th>
            ))}
            {!listManipulator.readOnly && (
              //  an invisible icon to make everything aligned
              <th
                style={{ width: COLUMN_WIDTHS[COLUMN_WIDTHS.length - 1] }}
                key="hidden-trash-icon"
              >
                <i className="top-label-for-trash vl-vi vl-vi-trash" />
              </th>
            )}
          </tr>
        </thead>
        <tbody className="compact-list">
          {map(generateElements(), (element, itemIndex) => (
            <React.Fragment key={element.id}>
              <tr className="row-with-inputs" id={"editor-" + element.id}>
                {map(element.renderElements(), (cellContent, index) => (
                  <td
                    data-title={
                      listComponent.listElementComponent.components[index].label
                    }
                    key={index}
                    className="editor-component-in-compact-list"
                  >
                    {cellContent}
                  </td>
                ))}
                {
                  /**
                   * TODO: don't use a div component here but a button.
                   * We still do because it is the only styling that looks decent.
                   */
                  !listManipulator.readOnly && !readOnly && (
                    <td
                      data-title={listComponent.inputOptions.labelForDelete}
                      title={listComponent.inputOptions.labelForDelete}
                      className="editor-component-in-compact-list"
                    >
                      {
                        <Popover
                          position={popoverPositions.RIGHT}
                          size={popoverSizes.LARGE}
                          toggleButtonType="tertiary"
                          toggleButtonContent={
                            <>
                              <i
                                className="vl-link__icon vl-link__icon--before vl-vi vl-vi-trash"
                                aria-hidden="true"
                              />
                              <span className="vl-u-hidden">
                                {listComponent.inputOptions.labelForDelete}
                              </span>
                            </>
                          }
                        >
                          <h4 className="vl-u-text--bold">
                            {gettext(
                              "Are you sure you want to remove this item?"
                            )}
                          </h4>

                          <div className="vl-action-group vl-action-group--align-right vl-u-spacer-top--xsmall">
                            <button
                              className="vl-button vl-button--icon-before delete-item-button"
                              onClick={(event) => {
                                event.stopPropagation();
                                deleteCandidate(itemIndex);
                                closePopovers();
                              }}
                            >
                              <i
                                className="vl-button__icon vl-button__icon--before vl-vi vl-vi-trash"
                                aria-hidden="true"
                              ></i>
                              <span className="vl-button__label">
                                {gettext("Yes, remove")}
                              </span>
                            </button>
                            <button
                              className="vl-button vl-button--secondary"
                              onClick={(e) => {
                                e.stopPropagation();
                                closePopovers();
                              }}
                            >
                              <span className="vl-button__label">
                                {gettext("No")}
                              </span>
                            </button>
                          </div>
                        </Popover>
                      }
                    </td>
                  )
                }
              </tr>
              {(element.errorMessages.length > 0 ||
                element.warningMessages.length > 0) && (
                <tr className="row-with-errors">
                  <td colSpan="6">
                    <InlineErrors
                      errorMessages={element.errorMessages}
                      warningMessages={element.warningMessages}
                    ></InlineErrors>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
          <tr className="row-with-total">
            <td
              colSpan="3"
              className="show-help-and-label-in-compact-list-anyway error"
              data-title={totalComponent.label}
            >
              {totalComponent.help && (
                <InlineHelp helpText={totalComponent.help}></InlineHelp>
              )}
              {totalIsInError && (
                <InlineErrors
                  errorMessages={totalErrorMessages}
                  warningMessages={totalWarningMessages}
                ></InlineErrors>
              )}
            </td>
            <td
              colSpan="2"
              className="show-help-and-label-in-compact-list-anyway label"
              data-title={totalComponent.label}
            >
              <InputLabel
                htmlFor={generatePathStringForManipulator(totalManipulator)}
                label={totalComponent.label}
                isRequired={totalManipulator.isRequired}
              ></InputLabel>
            </td>
            <td
              className="editor-component-in-compact-list total-value"
              data-title={totalComponent.label}
            >
              <div
                className={classnames(
                  "vl-form__group editor-component-in-compact-list",
                  { "vl-input-field--error": totalIsInError }
                )}
              >
                <EditorComponent
                  id={generatePathStringForManipulator(totalManipulator)}
                  key={generatePathStringForManipulator(totalManipulator)}
                  component={totalComponent}
                  manipulator={totalManipulator}
                  config={config}
                  nested={true}
                  readOnly={readOnly}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  function renderCustomVisualisation() {
    return (
      <InputField
        id={listManipulatorId}
        label={listComponent.label}
        isRequired={listManipulator.isRequired}
        helpInLine={listComponent.helpInLine}
        help={listComponent.help}
        shouldUseFullWidth={true}
      >
        {listIsInError && (
          <InlineErrors
            errorMessages={errorMessages(
              listManipulator,
              config.currentDocument.getIsReviewing(),
              false
            )}
            warningMessages={warningMessages(
              listManipulator,
              config.currentDocument.getIsReviewing(),
              false
            )}
          ></InlineErrors>
        )}
        {listManipulator.elementManipulators &&
          listManipulator.elementManipulators.length > 0 &&
          renderTable()}
        {!listManipulator.readOnly && !readOnly && (
          <div className="vl-data-table__actions vl-data-table__actions--bottom">
            <button
              type="button"
              className="vl-button vl-button--secondary add-item-in-compact-list"
              onClick={addElement}
            >
              <i
                className="vl-button__icon vl-vi vl-vi-plus vl-icon--before"
                aria-hidden="true"
              />
              <span>{listComponent.inputOptions.labelForAdd}</span>
            </button>
          </div>
        )}
      </InputField>
    );
  }

  function renderDefaultVisualisation() {
    return (
      <>
        <EditorComponent
          component={listComponent}
          manipulator={listManipulator}
          config={config}
          readOnly={readOnly}
        />
        <EditorComponent
          component={totalComponent}
          manipulator={totalManipulator}
          config={config}
          readOnly={readOnly}
        />
      </>
    );
  }

  return isMobileView
    ? renderDefaultVisualisation()
    : renderCustomVisualisation();
}

RoofParts.propTypes = ListInput.propTypes;

export default RoofParts;
