export default {
  technischeInstallaties:
    "https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/mijn-verbouwpremie-voor-elektriciteit-en-sanitair",
  binnenrenovatie:
    "https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/mijn-verbouwpremie-voor-binnenrenovatie",
  dakrenovatie:
    "https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/mijn-verbouwpremie-voor-dak",
  muurrenovatie:
    "https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/mijn-verbouwpremie-voor-buitenmuur",
  vloerrenovatie:
    "https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/mijn-verbouwpremie-voor-vloer",
  buitenschrijnwerkGlas:
    "https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/mijn-verbouwpremie-voor-ramen-en-deuren",
  warmtepomp:
    "https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/mijn-verbouwpremie-voor-warmtepomp",
  warmtepompboiler:
    "https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/mijn-verbouwpremie-voor-warmtepompboiler",
  zonneboiler:
    "https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/mijn-verbouwpremie-voor-zonneboiler",
  gascondensatieketel:
    "https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/mijn-verbouwpremie-voor-gascondensatieketel",
  zonnepanelen:
    "https://www.vlaanderen.be/zonnepanelen/steun-voor-zonnepanelen",
};
