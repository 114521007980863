import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { debounce, filter, find, get, isEmpty, map, omit } from "lodash";

import "@govflanders/vl-ui-core";
import "@govflanders/vl-ui-util";

import contextType from "@skryv/core-react/src/services/contextTypes";
import {
  dashboardPageWrapper,
  defaultProps,
  externalProps,
  internalProps,
} from "@skryv/core-react/src/pages/DashboardPage";

import { fetchDossierTasks } from "@skryv/core-react/src/core/store/actions/dossiers";
import router from "@skryv/core-react/src/services/router";
import { pageNames } from "@skryv/core-react/src/config/pages";

import PageContent from "@skryv/core-react-vo/src/components/layout/Content/Content";
import Contact from "@skryv/core-react-vo/src/components/base/Contact/Contact";
import CallToAction from "@skryv/core-react-vo/src/components/base/CallToAction/CallToAction";
import Notification, {
  externalProps as notificationProps,
} from "@skryv/core-react/src/components/base/Notification/Notification";
import Table from "@skryv/core-react-vo/src/components/base/Table/Table";
import Title from "@skryv/core-react-vo/src/components/base/Title/Title";
import Pager from "@skryv/core-react-vo/src/components/base/Pager/Pager";

import milestoneDefinitionKeysMvp from "../constants/mvp/milestoneDefinitionKeys";
import milestoneDefinitionKeysEpc from "../constants/epc/milestoneDefinitionKeys";
import milestoneDefinitionKeysMvl from "../constants/mvl/milestoneDefinitionKeys";

import { fetchFeatureToggles, fetchSecureDossiers } from "../store/actions";
import {
  selectFeatureToggles,
  selectSecureDossierList,
  selectSecureDossierListCount,
} from "../store/selectors";

class DashboardPage extends React.Component {
  static propTypes = {
    ...externalProps,
    ...internalProps,
    notifications: PropTypes.arrayOf(notificationProps.notification),
  };
  static defaultProps = defaultProps;
  static contextType = contextType;

  constructor(props) {
    super(props);
    props.fetchSecureDossiers();
    props.fetchFeatureToggles();

    this.state = {
      loadingDossiers: false,
      searchQuery: "",
    };
  }
  renderNotification() {
    return (
      this.props.notifications &&
      this.props.notifications.length > 0 && (
        <div className="dossier-notifications vl-col--1-1">
          {map(this.props.notifications, (notification) => (
            <Notification
              notification={notification}
              class="dossier-notification"
            />
          ))}
        </div>
      )
    );
  }

  renderCreatableDossiers() {
    // depending on the number of creatable dossiers, we decide how many columns we want to show
    const createDossierColumns = { 1: 6, 2: 6, 3: 4, 4: 3, 5: 4, 6: 4 };
    const createDossierButton = (dossierInformation, startButtonLabel) => {
      let buttonProps = {
        iconClass: dossierInformation.buttonIcon,
        iconPosition: dossierInformation.buttonPosition,
      };

      const shouldGoToDossierInfoPage =
        Boolean(
          get(this.props.dosdefs, [
            dossierInformation.dosdefKey,
            "data",
            "dossierInfoSteps",
          ])
        ) ||
        Boolean(
          get(this.props.dosdefs, [
            dossierInformation.dosdefKey,
            "data",
            "hasCustomDossierInfoPage",
          ])
        );

      if (shouldGoToDossierInfoPage) {
        return {
          ...buttonProps,
          labels: dossierInformation.buttonLabels || {
            active: startButtonLabel || this.context.gettext("More info"),
          },
          action: () => {
            this.props.toDossierInformation(dossierInformation.dosdefKey);
          },
        };
      }

      return {
        ...buttonProps,
        labels: dossierInformation.buttonLabels || {
          active:
            this.props.creatingDossierKey === dossierInformation.dosdefKey
              ? this.context.gettext(
                  "Starting dossier. We are fetching some data for you"
                )
              : startButtonLabel || this.context.gettext("Start"),
        },
        isDisabled:
          this.props.creatingDossierKey === dossierInformation.dosdefKey,
        isBusy: this.props.creatingDossierKey === dossierInformation.dosdefKey,
        action: () => this.props.createNewDossier(dossierInformation.dosdefKey),
      };
      // TODO: if goToDossierInformationPageFirst is false, we want to show a confirmation popup and let the user create a new dossier
    };

    const extractDossierDefinitionInformation = (dosdef) => {
      const creationModes = get(dosdef, ["data", "creationModes"]);
      return {
        key: get(dosdef, ["key"]),
        label: get(dosdef, ["label"]),
        description: get(dosdef, ["data", "description"]),
        startButtonLabel: get(dosdef, ["data", "startButtonLabel"]),
        isCreationModeEloket: Boolean(find(creationModes, { key: "eLoket" })),
      };
    };

    const filterOutCreatableDossiersBasedOnFeatureToggles = (
      dosdefs,
      featureToggles
    ) => {
      if (!featureToggles.activateMijnVerbouwLening) {
        dosdefs = omit(dosdefs, "mijn_verbouwlening");
      }
      if (!featureToggles.activateEpcLabelpremie) {
        dosdefs = omit(dosdefs, "epc_labelPremie");
      }

      return dosdefs;
    };

    const dossierDefinitions = filterOutCreatableDossiersBasedOnFeatureToggles(
      this.props.dosdefs,
      this.props.featureToggles
    );

    const creatableDossiers = filter(
      map(dossierDefinitions || {}, extractDossierDefinitionInformation),
      "isCreationModeEloket"
    );

    if (this.props.createDossierInformation) {
      return (
        <div className="dashboard-create-dossiers vl-col--1-1">
          <div className="create-dossiers-grid vl-grid vl-grid--v-stretch">
            {map(this.props.createDossierInformation, (dossierInformation) => {
              const {
                key,
                label,
                description,
                startButtonLabel,
                isCreationModeEloket,
              } = extractDossierDefinitionInformation(
                get(dossierDefinitions, [dossierInformation.dosdefKey])
              );

              if (isCreationModeEloket) {
                return (
                  <div
                    className={`vl-col--${
                      createDossierColumns[
                        this.props.createDossierInformation.length
                      ] || 6
                    }-12 vl-col--1-1--s`}
                    key={key}
                  >
                    <CallToAction
                      {...dossierInformation}
                      label={dossierInformation.label || label || "Dossier"}
                      body={description}
                      buttonInformation={createDossierButton(
                        dossierInformation,
                        startButtonLabel
                      )}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    }

    return (
      <div className="dashboard-create-dossiers vl-col--1-1">
        <div className="create-dossiers-grid vl-grid vl-grid--v-stretch">
          {map(creatableDossiers, (dosdef) => {
            const {
              key,
              label,
              description,
              startButtonLabel,
              isCreationModeEloket,
            } = dosdef;

            if (isCreationModeEloket) {
              return (
                <div
                  className={`vl-col--${
                    createDossierColumns[
                      Object.keys(creatableDossiers).length
                    ] || 6
                  }-12 vl-col--1-1--s`}
                  key={key}
                >
                  <CallToAction
                    {...dosdef}
                    label={label || "Dossier"}
                    body={description}
                    buttonInformation={createDossierButton(
                      { ...dosdef, dosdefKey: key },
                      startButtonLabel
                    )}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }

  startPolling(dossierId) {
    let interval;

    const fetchTasksAndCheckIfRequestTaskIsAvailable = () => {
      return this.props
        .fetchDossierTasks(dossierId)
        .then(({ api }) => {
          const details = api.response.data;
          if (!isEmpty(details)) {
            router.goToState(pageNames.TASK, { id: details[0].id });
            clearInterval(interval);
          }
        })
        .catch(() => {
          this.setState({ creatingDossierKey: undefined });
          clearInterval(interval);
        });
    };

    interval = setInterval(
      () => fetchTasksAndCheckIfRequestTaskIsAvailable(dossierId),
      3000
    );
  }

  renderMyDossiers() {
    function hasMilestone(key, milestones) {
      if (!milestones) return false;
      return !!find(milestones, { key });
    }

    function getLatestMilestone(milestones) {
      return milestones[0];
    }

    function filterActiveTasks(tasks) {
      if (!tasks) return [];
      return tasks.filter((task) => task.active);
    }

    const wrpDossierColumns = [
      {
        key: "label",
        text: "Dossier",
      },
      {
        key: "aanvraagDatum",
        text: "Ingediend op",
        formatter: (submitDate) => {
          if (submitDate !== undefined) {
            return moment(submitDate).format("DD-MM-YYYY");
          } else {
            return "Niet ingediend";
          }
        },
      },
      {
        key: "submit",
        text: "Uitvoeringsadres",
        formatter: (submit) => {
          if (
            submit.uitvoeringsadres !== undefined &&
            submit.aanvraagDatum !== undefined
          ) {
            return (
              <div>
                <p key="adress1" style={{ alignItems: "center" }}>
                  <span>{submit.uitvoeringsadres.street} </span>
                  <span>{submit.uitvoeringsadres.houseNumber}</span>
                  {submit.uitvoeringsadres.boxnumber ? (
                    <span>/{submit.uitvoeringsadres.boxnumber}</span>
                  ) : null}
                </p>
                <p key="adress2" style={{ alignItems: "center" }}>
                  <span>{submit.uitvoeringsadres.zipcode} </span>
                  <span>{submit.uitvoeringsadres.municipality}</span>
                </p>
              </div>
            );
          } else {
            return (
              <div>
                <span>Niet ingediend</span>
              </div>
            );
          }
        },
      },
      {
        key: "aanvrager",
        text: "Aanvrager",
        formatter: (aanvrager) => {
          if (isEmpty(aanvrager)) return null;
          if (aanvrager.naamOrganisatieMandaatnemer) {
            return (
              <div>
                <p key="aanvragerNaam" style={{ alignItems: "center" }}>
                  <span>{aanvrager.naam}</span>
                </p>
                <p
                  key="naamOrganisatieMandaat"
                  style={{ alignItems: "center" }}
                >
                  <span>door {aanvrager.naamOrganisatieMandaatnemer}</span>
                </p>
              </div>
            );
          }
          return aanvrager.naam;
        },
      },
      {
        key: "milestones",
        text: "Status",
        formatter: (milestones) => {
          if (isEmpty(milestones)) return null;

          const latestMilestone = getLatestMilestone(milestones).key;

          // this creates the html for the status element. the className determines the color, the text is the text that will appear in the status element.
          // vl-pill-error: red, vl-pill-success: green, vl-pill-warning: yellow, no class for transparent
          function createStatusElement(className, text) {
            return (
              <div className={className}>
                <span className="vl-pill__text">{text}</span>
              </div>
            );
          }

          // if one of these three milestones is the latestMilestone, show this status
          const latestMilestoneMap = {
            [milestoneDefinitionKeysMvp.ADDITIONAL_ATTACHMENTS_REQUESTED]:
              createStatusElement(
                "vl-pill vl-pill--error",
                "Dossier onvolledig"
              ),
            [milestoneDefinitionKeysMvp.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP]:
              createStatusElement(
                "vl-pill vl-pill--error",
                "Beroep: Dossier onvolledig"
              ),
            [milestoneDefinitionKeysMvp.UITBETALING_VOLTOOID_BEROEP]:
              createStatusElement(
                "vl-pill vl-pill--success",
                "Beroep uitbetaald"
              ),
            [milestoneDefinitionKeysEpc.ADDITIONAL_ATTACHMENTS_REQUESTED]:
              createStatusElement(
                "vl-pill vl-pill--error",
                "Dossier onvolledig"
              ),
            [milestoneDefinitionKeysEpc.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP]:
              createStatusElement(
                "vl-pill vl-pill--error",
                "Beroep: Dossier onvolledig"
              ),
          };

          // if the latestMilestone is in this list above, show this status element
          if (latestMilestoneMap.hasOwnProperty(latestMilestone)) {
            return latestMilestoneMap[latestMilestone];
          }

          // if the list of all milestones for this dossier includes one of below milestones, show according status
          const hasMilestoneMap = [
            {
              milestoneCondition: [
                milestoneDefinitionKeysMvl.ACTIEF_GEANNULEERD_MVL,
              ],
              className: "vl-pill vl-pill--error",
              text: "Geannuleerd",
            },
            {
              milestoneCondition: [milestoneDefinitionKeysMvl.TERUGBETAALD_MVL],
              className: "vl-pill vl-pill--success",
              text: "Terugbetaald",
            },
            {
              milestoneCondition: [milestoneDefinitionKeysMvl.OPGEZEGD_MVL],
              className: "vl-pill vl-pill--warning",
              text: "Opgezegd",
            },
            {
              milestoneCondition: [milestoneDefinitionKeysMvl.ACHTERSTAL_MVL],
              className: "vl-pill vl-pill--warning",
              text: "Achterstal",
            },
            {
              milestoneCondition: [milestoneDefinitionKeysMvl.GOEDGEKEURD_MVL],
              className: "vl-pill vl-pill--success",
              text: "Goedgekeurd",
            },
            {
              milestoneCondition: [milestoneDefinitionKeysMvl.GEANNULEERD_MVL],
              className: "vl-pill vl-pill--error",
              text: "Geannuleerd",
            },
            {
              milestoneCondition: [milestoneDefinitionKeysMvl.GEWEIGERD_MVL],
              className: "vl-pill vl-pill--error",
              text: "Geweigerd",
            },
            {
              milestoneCondition: [
                milestoneDefinitionKeysMvp.DECISION_LETTER_TO_CLIENT_BEROEP,
                milestoneDefinitionKeysMvp.DECISION_LETTER_BEROEP_ONGEGROND_TO_CLIENT,
                milestoneDefinitionKeysEpc.DECISION_MADE_BEROEP,
                milestoneDefinitionKeysEpc.DECISION_LETTER_BEROEP_ONGEGROND_TO_CLIENT,
              ],
              className: "vl-pill vl-pill--success",
              text: "Beroep beslist",
            },
            {
              milestoneCondition: [
                milestoneDefinitionKeysMvp.BEROEP_STARTED,
                milestoneDefinitionKeysEpc.BEROEP_STARTED,
              ],
              className: "vl-pill vl-pill--warning",
              text: "Beroep in behandeling",
            },
            {
              milestoneCondition: [
                milestoneDefinitionKeysMvp.UITBETALING_VOLTOOID,
              ],
              className: "vl-pill vl-pill--success",
              text: "Dossier uitbetaald",
            },
            {
              milestoneCondition: [
                milestoneDefinitionKeysMvp.BEROEP_SUBMITTED,
                milestoneDefinitionKeysEpc.BEROEP_SUBMITTED,
              ],
              className: "vl-pill vl-pill--success",
              text: "Beroep ingediend",
            },
            {
              milestoneCondition: [
                milestoneDefinitionKeysMvp.DECISION_LETTER_TO_CLIENT,
                milestoneDefinitionKeysEpc.DECISION_MADE,
              ],
              className: "vl-pill vl-pill--success",
              text: "Beslist",
            },
            {
              milestoneCondition: [
                milestoneDefinitionKeysMvl.DOSSIER_DEF_REJECTED_MVL,
              ],
              className: "vl-pill vl-pill--error",
              text: "Geweigerd",
            },
            {
              milestoneCondition: [
                milestoneDefinitionKeysMvp.REQUEST_SUBMITTED,
                milestoneDefinitionKeysMvl.REQUEST_SUBMITTED,
                milestoneDefinitionKeysEpc.REQUEST_SUBMITTED,
                milestoneDefinitionKeysMvl.IN_BEHANDELING_MVL,
              ],
              className: "vl-pill vl-pill--warning",
              text: "In behandeling",
            },
          ];

          //go over this list of milestones that have to show a status. If it is found in de list of all milestones, show the corresponding status
          for (const element of hasMilestoneMap) {
            if (
              element.milestoneCondition.some((milestone) =>
                hasMilestone(milestone, milestones)
              )
            ) {
              return createStatusElement(element.className, element.text);
            }
          }

          return createStatusElement("vl-pill", "Concept");
        },
      },
      {
        key: "tasks",
        text: "Required actions",
        formatter: (tasks) => {
          const activeTasks = filterActiveTasks(tasks);
          return (
            activeTasks.length > 0 &&
            activeTasks.map((task, index) => {
              return (
                <button className="vl-button vl-button--tertiary" key={index}>
                  <span className="vl-button__label">{task.name}</span>
                </button>
              );
            })
          );
        },
      },
    ];

    const filterDossiersList = debounce((searchQuery, pageNumber = 0) => {
      this.setState({ loadingDossiers: true });
      const query = {
        query: {
          bool: {
            filter: [
              { term: { type: "dossier" } },
              ...(isEmpty(searchQuery)
                ? []
                : [
                    {
                      bool: {
                        should: [
                          {
                            nested: {
                              path: "dossier",
                              query: {
                                query_string: {
                                  query: searchQuery,
                                  fields: ["dossier.label"],
                                },
                              },
                            },
                          },
                          {
                            nested: {
                              path: "document",
                              query: {
                                query_string: {
                                  query: searchQuery,
                                  fields: [
                                    "document.aanvraag.zoekenOpAanvraagdatum",
                                    "document.loginData.naam.searchable",
                                    "document.loginData.voornaam.searchable",
                                    "document.loginData.insz.searchable",
                                    "document.loginData.naamOrganisatie.searchable",
                                    "document.loginData.naamOrganisatieMandaatnemer.searchable",
                                    "document.aanvraag.zoekenOpStraat.searchable",
                                    "document.aanvraag.zoekenOpHuisnummer.searchable",
                                    "document.aanvraag.zoekenOpGemeente.searchable",
                                    "document.aanvraag.zoekenOpPostcode.searchable",
                                    "document.contactformulier.aanvraag.emailAdres",
                                    "document.contactformulier.aanvraag.telefoonnummer",
                                    "document.contactformulier.contactgegevens.eMailadres",
                                    "document.contactformulier.contactgegevens.telefoonnummer.searchable",
                                    "document.gegevens_klant_andere_aanvrager.nummerVanDeEnergielening.searchable",
                                  ],
                                },
                              },
                            },
                          },
                          {
                            nested: {
                              path: "document",
                              query: {
                                query_string: {
                                  query: searchQuery.replace("-", ""),
                                  fields: [
                                    "document.gegevens_klant_andere_aanvrager.nummerVanDeEnergielening.searchable",
                                  ],
                                },
                              },
                            },
                          },
                        ],
                      },
                    },
                  ]),
            ],
          },
        },
        from: pageNumber * this.props.itemsPerPage,
        size: this.props.itemsPerPage,
        sort: {
          "dossier.latestActivity": {
            order: "desc",
            nested: { path: "dossier" },
          },
        },
      };
      this.props
        .fetchSecureDossiers({ query, listKey: "DASHBOARD" })
        .then(() => this.setState({ loadingDossiers: false }));
    }, 500);

    const handleFilterTableAction = (e) => {
      this.setState({ searchQuery: e.target.value });
      filterDossiersList(e.target.value);
    };

    const searchDossiersOnClick = (pageNumber) => {
      filterDossiersList(this.state.searchQuery, pageNumber);
    };

    return (
      <section className="vl-infoblock dashboard-my-dossiers vl-col--1-1">
        <header className="vl-infoblock__header" role="presentation">
          <i
            className="vl-infoblock__header__icon vl-vi vl-vi-news"
            aria-hidden="true"
          />
          <h2 className="vl-infoblock__title">
            {this.context.gettext("My dossiers")}
          </h2>
        </header>
        <div className="vl-infoblock__content">
          <Table
            tableData={this.props.mySecureDossiers}
            tableColumns={wrpDossierColumns}
            itemClickAction={(dossier) => this.props.toDossier(dossier.id)}
            noDataText={this.context.gettext("No dossiers yet")}
            filterTableAction={handleFilterTableAction}
          />
          <Pager
            totalItemCount={this.props.mySecureDossiersLength}
            itemsPerPage={this.props.itemsPerPage}
            handleNextClick={searchDossiersOnClick}
            handlePrevClick={searchDossiersOnClick}
          />
        </div>
      </section>
    );
  }

  renderContactInformation() {
    if (!this.props.contactInformation) return null;
    return (
      <div className="dashboard-contact vl-col--1-1">
        <section className="vl-infoblock">
          <Title
            level={2}
            title={this.context.gettext("Contact")}
            iconClass="vl-vi-chat"
          />
          <Contact contactInformation={this.props.contactInformation} />
        </section>
      </div>
    );
  }

  render() {
    return (
      <>
        <PageContent contentName="dashboard">
          {this.props.shouldShowNotifications && this.renderNotification()}
          {this.props.shouldShowCreateDossiers &&
            this.renderCreatableDossiers()}
          {this.props.shouldShowMyDossiers && this.renderMyDossiers()}
          {this.props.shouldShowContactInformation &&
            this.renderContactInformation()}
        </PageContent>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  mySecureDossiers: selectSecureDossierList(state, "DASHBOARD"),
  mySecureDossiersLength: selectSecureDossierListCount(state, "DASHBOARD"),
  featureToggles: selectFeatureToggles(state),
  itemsPerPage: 10,
});

export default connect(mapStateToProps, {
  fetchSecureDossiers,
  fetchDossierTasks,
  fetchFeatureToggles,
})(dashboardPageWrapper(DashboardPage));
