import React, { useContext } from "react";
import { keyBy, map, some } from "lodash";
import classnames from "classnames";

import useMultiChoice, {
  containerComponentProps,
} from "@skryv/core-react/src/components/form/editor/MultiChoiceInput/multiChoiceHelpers";
import { TranslationsContext } from "@skryv/core-react/src/services/translations";
import InputField from "@skryv/core-react-vo/src/components/base/InputFields/InputField/InputField";

import categoryIcons from "../../../constants/iconsForCategories";
import categoryHelpHrefs from "../../../constants/helpHrefForCategories";

import "./CategoryTiles.scss";

function CategoryTiles({ component, manipulator, readOnly, ...otherProps }) {
  const {
    id,
    name,
    label,
    options,
    onChange,
    isRequired,
    help,
    helpInLine,
    errorMessages = [],
    warningMessages = [],
  } = useMultiChoice(
    { component, manipulator, readOnly, ...otherProps },
    () => {}
  );
  const { gettext } = useContext(TranslationsContext);

  const hasErrors = errorMessages.length > 0;

  const categoryComponents = keyBy(component.options, "name");

  function buildReadOnlyInput() {
    return (
      <div>
        {!some(options, "isSelected") ? (
          <p className="vl-form__annotation">{gettext("n/a")}</p>
        ) : (
          options.map(
            (option) =>
              option.isSelected && (
                <div key={option.value}>
                  <p className="vl-form__annotation read-only">
                    {option.label}
                  </p>
                  {options.map(
                    (option) =>
                      option.nestedFields &&
                      option.isSelected &&
                      option.nestedFields()
                  )}
                </div>
              )
          )
        )}
      </div>
    );
  }

  function buildEditableInput() {
    return (
      <div className="wrp-category-tiles">
        {map(options, (option) => {
          return (
            <div
              className={classnames(
                "vl-checkbox-tile",
                { "vl-checkbox-tile--disabled": option.isDisabled },
                { "vl-checkbox-tile--error": hasErrors }
              )}
              key={option.value}
            >
              <input
                className="vl-checkbox-tile__input"
                type="checkbox"
                id={`${id}-checkbox-${option.value}`}
                name={`${id}-checkbox-${option.value}`}
                checked={option.isSelected === true}
                onChange={() => onChange(option.value)}
                aria-required={isRequired}
                aria-invalid={hasErrors}
                disabled={option.isDisabled}
              />
              <label
                htmlFor={`${id}-checkbox-${option.value}`}
                className="vl-checkbox-tile__label"
              >
                <div>
                  <span
                    className={classnames(
                      "vl-icon vl-checkbox-tile__icon vl-u-spacer-right--xsmall",
                      categoryIcons[option.value] || "vl-vi vl-vi-places-home"
                    )}
                    aria-hidden="true"
                  ></span>
                </div>
                <div>
                  <span
                    className={classnames("vl-checkbox-tile__title", {
                      "has-info": !!categoryComponents[option.value].help,
                    })}
                  >
                    {categoryComponents[option.value].label}
                  </span>
                  {!!categoryComponents[option.value].help && (
                    <span className="vl-checkbox-tile__info">
                      {categoryComponents[option.value].help}
                    </span>
                  )}
                  {!!categoryHelpHrefs[option.value] && (
                    <a
                      target="_blank"
                      href={categoryHelpHrefs[option.value]}
                      className="vl-link"
                      rel="noreferrer"
                    >
                      Meer informatie
                      <span className="vl-u-visually-hidden">
                        (opent in nieuw venster)
                      </span>
                      <span
                        className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external"
                        aria-hidden="true"
                      ></span>
                    </a>
                  )}
                </div>
              </label>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <InputField
      id={id}
      name={name}
      label={label}
      isRequired={isRequired}
      helpInLine={helpInLine}
      help={help}
      errorMessages={errorMessages}
      warningMessages={warningMessages}
      shouldUseFullWidth={true}
    >
      {readOnly ? buildReadOnlyInput() : buildEditableInput()}
    </InputField>
  );
}

CategoryTiles.propTypes = containerComponentProps;

export default CategoryTiles;
