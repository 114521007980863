import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import useText from "@skryv/core-react/src/components/form/editor/TextInput/textInputHelpers";

import Text from "@skryv/core-react-vo/src/components/base/InputFields/Text/Text";
import Information from "@skryv/core-react-vo/src/components/base/InputFields/Information/Information";

import "./EANWithAddress.scss";

const EAN_CODE_FIELD_KEY = "eanCodeWoning";
const EAN_ADDRESS_FIELD_KEY = "eanAddress";
const EAN_STATUS_FIELD_KEY = "eanStatus";
const PARENT_CATEGORIES_FIELD_KEY = "parentCategories";
const CHILD_CATEGORIES_FIELD_KEY = "childCategories";
const UITVOERINGSADRES_FIELD_KEY = "uitvoeringsadres";
const TOGGLE_APPARTEMENTEN_KEY = "pcToggleAanvraag";

const DOMICILIE_CHECK_CE = "werkenOpDomicilie";

const EAN_STATUS_MAPPER = {
  SUCCESS: "Success",
  EAN_NOT_ELECTRICITY: "EanNotElectricity",
  EAN_OUTSIDE_FLANDERS: "EanOutsideFlanders",
  TECHNICAL_ERROR: "TechnicalError",
  UNAVAILABLE: "ServiceUnavailable",
};

const INFO_ADDRESS_LOADING = {
  id: "addressLoading",
  name: "addressLoading",
  label: "Even geduld...",
  type: "information",
  help: "We halen het adres op dat bij bovenstaande EAN-code hoort",
  helpInLine: true,
  visualisation: "callout-info",
};

/*
  In this component we make a number of assumptions of what the docdef looks like.
  This component needs to be a fieldset that contains:
  - a nested fieldset with the name "eanAddress" that contains the fields "street", "zipcode" & "municipality"
      These fields will be filled at the time of fetching the EAN metadata.
      They can then be used by other fields in the form to do calculations.
  - a boolean field and another address field
      These fields which will be used to define whether an editable address component
      is available to the user. If the boolean is false, this editable address field is prefilled with the
      data from the EAN response and rendered readonly EXCEPT for the housenumber and boxnumber fields. These are not retrieved from the
      EAN response for privacy purposes and the user has to fill in this data.
  - a choice field with the strings from "EAN_STATUS_MAPPER" as options
      This field will be filled in when fetching the EAN metadata. It will contain the status of the fetch: whether it was successful or failed for a specific reason.
      Based on the selected option, the user can be informed or other actions can be taken

  See the stories for a functional example.
*/
export default function EANWithAddressContainer({
  manipulator,
  component,
  config,
  ...otherProps
}) {
  const [isAddressLoading, setIsAddressLoading] = useState(false);
  const isMounted = useRef(false);

  const EANManipulator = manipulator.propertyManipulators[EAN_CODE_FIELD_KEY];
  const EANComponent = component.components[0];
  const { onChange: textOnChange, ...textProps } = useText({
    manipulator: EANManipulator,
    component: EANComponent,
    config,
    ...otherProps,
  });

  const eanAddressManipulator =
    manipulator.propertyManipulators[EAN_ADDRESS_FIELD_KEY];
  const eanStatusManipulator =
    manipulator.propertyManipulators[EAN_STATUS_FIELD_KEY];
  const uitvoeringsAdresManipulator =
    manipulator.parent.propertyManipulators[UITVOERINGSADRES_FIELD_KEY];
  const parentCategoriesManipulator =
    manipulator.parent.propertyManipulators[PARENT_CATEGORIES_FIELD_KEY];
  const childCategoriesManipulator =
    manipulator.parent.propertyManipulators[CHILD_CATEGORIES_FIELD_KEY];
  const toggleAppartementenManipulator =
    manipulator.parent.propertyManipulators[TOGGLE_APPARTEMENTEN_KEY];

  const domicilieCheckCE = manipulator.computedExpressions[DOMICILIE_CHECK_CE];

  useEffect(() => {
    retrieveAddressByEAN();
  }, [retrieveAddressByEAN]);

  useEffect(() => {
    // only clear the house number of the check changes _after_ the initial render
    // this way we don't do the clear when the checkbox was initially unchecked, but only when the user unchecks it
    if (!domicilieCheckCE && isMounted.current) {
      clearHouseAndBoxOfUitvoeringsadres();
    }
    isMounted.current = true;
  }, [domicilieCheckCE, clearHouseAndBoxOfUitvoeringsadres]);

  const retrieveEANResponse = config.customRequest.retrieveEANResponse;
  const retrieveCategoriesResponse =
    config.customRequest.retrieveCumulCategories;
  const retrieveToggleAppartementen =
    config.customRequest.retrieveToggleAppartementen;

  const retrieveAddressByEAN = useCallback(() => {
    if (
      !EANManipulator.value ||
      (EANManipulator.errors && EANManipulator.errors.length > 0)
    ) {
      clearAddressAndStatus();
      return;
    }

    setIsAddressLoading(true);

    retrieveEANResponse(`54144${EANManipulator.value}`)
      .then(({ status, address, superAansluitObjectId, aansluitObjectId }) => {
        fillInStatus(status);
        if (EAN_STATUS_MAPPER[status] === "Success") {
          fillInAddress(address.street, address.zip, address.city);
          if (toggleAppartementenManipulator) {
            retrieveToggleAppartementen().then((toggle) => {
              fillInToggleAppartementeninAanvraag(toggle);
            });
          }
          if (
            (superAansluitObjectId || aansluitObjectId) &&
            parentCategoriesManipulator &&
            childCategoriesManipulator
          ) {
            retrieveCategoriesResponse(
              aansluitObjectId,
              superAansluitObjectId
            ).then((categoryLists) => {
              fillInCategoriesForParentAndChildDossiers(categoryLists);
            });
          }
        }
      })
      .catch(() => {
        fillInStatus("TECHNICAL_ERROR");
      })
      .finally(() => {
        setIsAddressLoading(false);
      });
  }, [
    retrieveEANResponse,
    retrieveCategoriesResponse,
    retrieveToggleAppartementen,
    EANManipulator.errors,
    EANManipulator.value,
    fillInAddress,
    fillInStatus,
    fillInCategoriesForParentAndChildDossiers,
    fillInToggleAppartementeninAanvraag,
    clearAddressAndStatus,
    parentCategoriesManipulator,
    childCategoriesManipulator,
    toggleAppartementenManipulator,
  ]);

  // Fills in the address in the underlying address field.
  // Note that this field is different from the one being shown
  // in the user interface and is only used as intermediate storage.
  // The one being shown should be read-only except for the housenumber
  // and boxnumber fields which makes it impossible to fill in using the
  // api call if they are marked read-only.
  const fillInAddress = useCallback(
    (street, zipcode, municipality) => {
      eanAddressManipulator.propertyManipulators.street.value = street;
      eanAddressManipulator.propertyManipulators.zipcode.value = zipcode;
      eanAddressManipulator.propertyManipulators.municipality.value =
        municipality;
    },
    [
      eanAddressManipulator.propertyManipulators.street,
      eanAddressManipulator.propertyManipulators.zipcode,
      eanAddressManipulator.propertyManipulators.municipality,
    ]
  );

  // Fills in the status of the "retrieve address by ean" call
  const fillInStatus = useCallback(
    (status) => {
      eanStatusManipulator.selectedOption = EAN_STATUS_MAPPER[status];
    },
    [eanStatusManipulator]
  );

  // Fills in the categories a parent dossier or a child dossier can or cannot request.
  const fillInCategoriesForParentAndChildDossiers = useCallback(
    (categoryLists) => {
      const parentCategoriesResponse = categoryLists.parent;
      const childCategoriesResponse = categoryLists.child;

      const updateCategories = (categoryListResponse, manipulator) => {
        Object.keys(categoryListResponse).forEach((category) => {
          manipulator.propertyManipulators[category].value =
            categoryListResponse[category];
        });
      };

      updateCategories(parentCategoriesResponse, parentCategoriesManipulator);
      updateCategories(childCategoriesResponse, childCategoriesManipulator);
    },
    [childCategoriesManipulator, parentCategoriesManipulator]
  );

  //Fills in the fetched toggle from appartementen in the aanvraag.
  const fillInToggleAppartementeninAanvraag = useCallback(
    (toggle) => {
      toggleAppartementenManipulator.value = toggle;
    },
    [toggleAppartementenManipulator]
  );

  const clearAddressAndStatus = useCallback(() => {
    fillInAddress(undefined, undefined, undefined);
    fillInStatus(undefined);
  }, [fillInAddress, fillInStatus]);

  const clearHouseAndBoxOfUitvoeringsadres = useCallback(() => {
    uitvoeringsAdresManipulator.propertyManipulators.housenumber.value =
      undefined;
    uitvoeringsAdresManipulator.propertyManipulators.boxnumber.value =
      undefined;
  }, [
    uitvoeringsAdresManipulator.propertyManipulators.housenumber,
    uitvoeringsAdresManipulator.propertyManipulators.boxnumber,
  ]);

  function onChange(value) {
    textOnChange(value);
    retrieveAddressByEAN();
  }

  return (
    <EANWithAddress
      onEANChange={onChange}
      textProps={textProps}
      isAddressLoading={isAddressLoading}
      {...otherProps}
    />
  );
}

EANWithAddressContainer.propTypes = {
  component: PropTypes.shape({
    label: PropTypes.string,
    components: PropTypes.array,
    helpInLine: PropTypes.bool,
    help: PropTypes.string,
    inputOptions: PropTypes.shape({
      maskConfig: PropTypes.shape({
        predefinedMask: PropTypes.object,
      }),
    }),
  }).isRequired,
  manipulator: PropTypes.shape({
    propertyManipulators: PropTypes.shape({
      [EAN_CODE_FIELD_KEY]: PropTypes.shape({
        value: PropTypes.string,
        readOnly: PropTypes.bool,
        isRequired: PropTypes.bool,
        errors: PropTypes.array,
      }),
      [INFO_ADDRESS_LOADING]: PropTypes.shape({}),
      [TOGGLE_APPARTEMENTEN_KEY]: PropTypes.shape({
        value: PropTypes.bool,
      }),
      [PARENT_CATEGORIES_FIELD_KEY]: PropTypes.shape({
        value: PropTypes.bool,
      }),
    }),
    parent: PropTypes.shape({
      propertyManipulators: PropTypes.shape({
        [UITVOERINGSADRES_FIELD_KEY]: PropTypes.shape({
          propertyManipulators: PropTypes.shape({
            housenumber: PropTypes.shape({ value: PropTypes.string }),
            boxnumber: PropTypes.shape({ value: PropTypes.string }),
          }),
        }),
      }),
    }),
    computedExpressions: PropTypes.shape({
      [DOMICILIE_CHECK_CE]: PropTypes.bool,
    }),
  }).isRequired,
  config: PropTypes.shape({
    currentDocument: PropTypes.shape({
      getIsReviewing: PropTypes.func,
    }),
    customRequest: PropTypes.shape({
      retrieveEANResponse: PropTypes.func,
      retrieveCumulCategories: PropTypes.func,
      retrieveToggleAppartementen: PropTypes.func,
    }),
  }).isRequired,
  nested: PropTypes.bool,
};

export function EANWithAddress({
  onEANChange,
  textProps,
  isAddressLoading,
  ...otherProps
}) {
  const loadingAlert = isAddressLoading ? (
    <div className="addressLoading-alert">
      <Information {...INFO_ADDRESS_LOADING} {...otherProps} />
    </div>
  ) : null;

  return (
    <>
      <Text {...textProps} onChange={onEANChange} />
      {loadingAlert}
    </>
  );
}

EANWithAddress.propTypes = {
  onEANChange: PropTypes.func,
  textProps: PropTypes.shape({}),
  address: PropTypes.shape({
    street: PropTypes.string,
    housenumber: PropTypes.string,
    boxnumber: PropTypes.string,
    zipcode: PropTypes.string,
    municipality: PropTypes.string,
  }),
  isAddressLoading: PropTypes.bool,
};
