import React, { useEffect, useState } from "react";
import moment from "moment";
import { get, map } from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  dossierPageWrapper as coreDossierPageWrapper,
  externalProps,
  internalProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import { DossierPage as CoreDossierPage } from "@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage";
import router from "@skryv/core-react/src/services/router";
import { pageNames } from "@skryv/core-react/src/config/pages";

import contactInformation from "../../../constants/contactInformation";
import documentDefinitionKeys from "../../../constants/mvp/documentDefinitionKeys";
import milestoneDefinitionKeys from "../../../constants/mvp/milestoneDefinitionKeys";
import categoryIcons from "../../../constants/iconsForCategories";
import {
  accountNumber,
  categoryList,
  applicant,
  submitDate,
  status,
  eanNumberAndAdress,
  communication,
} from "../dossierPageHelpers";
import * as notifications from "../notificationHelpers";

import { fetchWrpProfile } from "../../../store/actions";
import { is1700Employee } from "../../../store/selectors";

//link milestone to notification
const notificationsForMilestones = {
  [milestoneDefinitionKeys.REQUEST_INITIATED]:
    notifications.notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.FETCH_DATA_CITIZEN]:
    notifications.notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.FETCH_DATA_COMPANY]:
    notifications.notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.FILLING_IN_REQUEST]:
    notifications.notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.BEROEP_SUBMITTED]:
    notifications.notificationBeroepRequestSubmitted,
  [milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED]:
    notifications.notificationAdditionalAttachmentsNeeded,
  [milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP]:
    notifications.notificationAdditionalAttachmentsNeededBeroep,
  [milestoneDefinitionKeys.CHANGE_OF_REQUESTOR_REQUESTED]:
    notifications.notificationToModifyRequestor,
  [milestoneDefinitionKeys.BEROEP_TEGEN_STILZITTEN_INGEDIEND]:
    notifications.notitificationAppealWaitingTooLong,
  [milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT]:
    notifications.notitificationDossierDecided,
  [milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT_BEROEP]:
    notifications.notitificationBeroepDecided,
  [milestoneDefinitionKeys.BEROEP_NOT_VALID]:
    notifications.notitificationBeroepDecided,
  // some other notifications are shown only when a more complex rule is true, e.g. a certain milestone is present, but another one is not
  // see function notifications()
};

const loaderLabelForMilestones = {
  [milestoneDefinitionKeys.REQUEST_INITIATED]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.FETCH_DATA_COMPANY]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.FETCH_DATA_CITIZEN]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.REQUEST_INITIATED_IN_BO]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.REQUEST_SUBMITTED]: "We dienen uw aanvraag in.",
};

const premiumAmountFieldForCategory = new Map([
  ["binnenrenovatie", "premieBinnenrenovatie"],
  ["muurrenovatie", "premieMuur"],
  ["dakrenovatie", "premieDak"],
  ["technischeInstallaties", "premieBElektriciteitEnSanitair"],
  ["gascondensatieketel", "premieGascondensatieketel"],
  ["buitenschrijnwerkGlas", "premieRamenEnDeuren"],
  ["vloerrenovatie", "premieVloer"],
  ["warmtepomp", "premieWarmtepomp"],
  ["warmtepompboiler", "premieWarmtepompboiler"],
  ["zonneboiler", "premieZonneboiler"],
]);

const mostRecentPremiumAmountFieldForCategory = new Map([
  ["binnenrenovatie", "recenstePremieBinnenrenovatie"],
  ["muurrenovatie", "recenstePremieMuur"],
  ["dakrenovatie", "recenstePremieDak"],
  ["technischeInstallaties", "recenstePremieElektriciteitEnSanitair"],
  ["gascondensatieketel", "recenstePremieGascondensatieketel"],
  ["buitenschrijnwerkGlas", "recenstePremieRamenEnDeuren"],
  ["vloerrenovatie", "recenstePremieVloer"],
  ["warmtepomp", "recenstePremieWarmtepomp"],
  ["warmtepompboiler", "recenstePremieWarmtepompboiler"],
  ["zonneboiler", "recenstePremieZonneboiler"],
]);

export function mvpDossierPageWrapper() {
  function MvpDossierPage(props) {
    const [loaderLabel, setLoaderLabel] = useState(
      loaderLabelForMilestones[props.getLatestMilestoneKey()]
    );
    const [fetchCurrentMilestoneInterval, setFetchCurrentMilestoneInterval] =
      useState(false);
    const [
      loadingDossierDetailsAfterMilestonesInterval,
      setLoadingDossierDetailsAfterMilestonesInterval,
    ] = useState(false);

    const dossierId = props.dossier.id;
    const fetchDossierDetails = props.fetchDossierDetails;
    const fetchCurrentDossierMilestone = props.fetchCurrentDossierMilestone;

    /* The current milestone is fetched every so seconds until no loaderLabel is defined for the current milestone */
    useEffect(() => {
      function cleanup() {
        if (fetchCurrentMilestoneInterval) {
          setFetchCurrentMilestoneInterval(false);
          clearInterval(fetchCurrentMilestoneInterval);

          // just to be sure, we have the latest dossier, we do one fetch of the dossier details
          setLoadingDossierDetailsAfterMilestonesInterval(true);
          fetchDossierDetails(dossierId).then(() =>
            setLoadingDossierDetailsAfterMilestonesInterval(false)
          );
        }
      }

      // getLatestMilestoneKey is based on the dossier details; here we're fetching the currentMilestone separately, so we need to watch that one
      const currentMilestone = props.currentMilestone?.key;
      const newLoaderLabel = loaderLabelForMilestones[currentMilestone];
      setLoaderLabel(loaderLabelForMilestones[currentMilestone]);

      if (newLoaderLabel && !fetchCurrentMilestoneInterval) {
        setFetchCurrentMilestoneInterval(
          setInterval(() => {
            fetchCurrentDossierMilestone(dossierId);
          }, 2000)
        );
      }
      if (!newLoaderLabel && fetchCurrentMilestoneInterval) {
        cleanup();
      }
      return () => {
        cleanup();
      };
    }, [
      fetchCurrentMilestoneInterval,
      loaderLabel,
      dossierId,
      fetchDossierDetails,
      fetchCurrentDossierMilestone,
      props.currentMilestone,
    ]);

    const { fetchWrpProfile } = props;

    useEffect(() => {
      fetchWrpProfile();
    }, [fetchWrpProfile]);

    //constants needed for notifications
    const dossierType = props.dossier.dossierDefinitionKey;
    const requestDate = moment(
      props.getFieldFromDocument(documentDefinitionKeys.REQUEST, [
        "aanvraagdatum",
      ])
    );

    //constants needed for calculation
    const isBeforeJuly2024 = new Date(requestDate) <= new Date("2024-06-30");
    const decisionDate = isBeforeJuly2024
      ? moment(
          props.getFieldFromDocument(documentDefinitionKeys.REQUEST, [
            "aanvraagdatum",
          ])
        ).add(8, "M")
      : moment(
          props.getFieldFromDocument(documentDefinitionKeys.REQUEST, [
            "aanvraagdatum",
          ])
        ).add(6, "M");

    const valuesForNotifications = {
      isBeforeJuly2024: isBeforeJuly2024,
      requestDate: requestDate,
      decisionDate: decisionDate,
      appealRequestDate: moment(
        props.getMilestone(milestoneDefinitionKeys.BEROEP_SUBMITTED)?.timestamp
      ),
      appealDecisionDate: moment(
        props.getMilestone(milestoneDefinitionKeys.BEROEP_SUBMITTED)?.timestamp
      ).add(3, "M"),
      additionalAttachmentsRequested: moment(
        props.getFieldFromDocument(documentDefinitionKeys.WRP_WACHTTIJD_BS, [
          "wachttijd",
        ])
      ),
      additionalAttachmentsDelivered: moment(
        props.getMilestone(
          milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED
        )?.timestamp
      ),
      additionalAttachmentsDeliveredBeroep: moment(
        props.getMilestone(
          milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED_BEROEP
        )?.timestamp
      ),
      deadlineForModificationRequestor: moment(
        props.getMilestone(
          milestoneDefinitionKeys.CHANGE_OF_REQUESTOR_REQUESTED
        )?.timestamp
      ).add(1, "M"),
      rekeningnummer: props.getFieldFromDocument(
        documentDefinitionKeys.REQUEST,
        ["accountnumber"]
      ),
    };

    const milestoneForAppeal = props.getMilestone(
      milestoneDefinitionKeys.BEROEP_SUBMITTED
    );
    const milestoneForReopening = props.getMilestone(
      milestoneDefinitionKeys.DOSSIER_REOPENED
    );

    function appealIsAfterReopening() {
      if (milestoneForAppeal && milestoneForReopening) {
        return moment(milestoneForAppeal.timestamp).isAfter(
          moment(milestoneForReopening.timestamp)
        );
      }
      return false;
    }

    const getNotificationForLatestMilestone = () => {
      // If there is a specified notification for the milestone, show this notification (see list of constants at top of page)
      let notificationForLatestMilestone =
        notificationsForMilestones[props.getLatestMilestoneKey()];

      // If the milestone is not loaded yet, show no notification
      if (!props.getLatestMilestoneKey()) return;

      // If there is no notification for the current milestone, and the milestone is not request submitted
      // go through these rules below to figure out what notification has to be shown
      if (
        !notificationForLatestMilestone &&
        props.getLatestMilestoneKey() !==
          milestoneDefinitionKeys.REQUEST_SUBMITTED
      ) {
        if (
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP
          ) &&
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED_BEROEP
          ) &&
          appealIsAfterReopening
        ) {
          notificationForLatestMilestone =
            notifications.notificationAdditionalAttachmentsDeliveredBeroep;
        } else if (
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED
          ) &&
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED
          ) &&
          props.getMilestone(milestoneDefinitionKeys.DOSSIER_REOPENED)
        ) {
          notificationForLatestMilestone =
            notifications.notificationAdditionalAttachmentsDeliveredHeropening;
        } else if (
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP
          ) &&
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED_BEROEP
          )
        ) {
          notificationForLatestMilestone =
            notifications.notificationAdditionalAttachmentsDeliveredBeroep;
        } else if (
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED
          ) &&
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED
          )
        ) {
          notificationForLatestMilestone =
            notifications.notificationAdditionalAttachmentsDelivered;
        } else if (
          props.getMilestone(
            milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT_BEROEP
          ) ||
          props.getMilestone(milestoneDefinitionKeys.BEROEP_NOT_VALID)
        ) {
          notificationForLatestMilestone =
            notifications.notitificationBeroepDecided;
        } else if (
          props.getMilestone(milestoneDefinitionKeys.BEROEP_SUBMITTED)
        ) {
          notificationForLatestMilestone =
            notifications.notificationBeroepRequestSubmitted;
        } else if (
          props.getMilestone(milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT)
        ) {
          notificationForLatestMilestone =
            notifications.notitificationDossierDecided;
        } else {
          notificationForLatestMilestone =
            notifications.notificationRequestSubmitted;
        }
      }
      return notificationForLatestMilestone;
    };

    const milestonesForStatus = {
      latestMilestoneKey: props.getLatestMilestoneKey(),
      additonalAttachmentRequestedMilestoneKey:
        milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED,
      additionalAttachmentRequestedBeroepMilestoneKey:
        milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP,
      milestoneUitbetalingVoltooidBeroep: props.getMilestone(
        milestoneDefinitionKeys.UITBETALING_VOLTOOID_BEROEP
      ),
      milestoneDecisionLetterToClientBeroep: props.getMilestone(
        milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT_BEROEP
      ),
      milestoneBeroepNotValid: props.getMilestone(
        milestoneDefinitionKeys.BEROEP_NOT_VALID
      ),
      milestoneBeroepStarted: props.getMilestone(
        milestoneDefinitionKeys.BEROEP_STARTED
      ),
      milestoneUitbetalingVoltooid: props.getMilestone(
        milestoneDefinitionKeys.UITBETALING_VOLTOOID
      ),
      milestoneBeroepSubmitted: props.getMilestone(
        milestoneDefinitionKeys.BEROEP_SUBMITTED
      ),
      milestoneDecisionLetterToClient: props.getMilestone(
        milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT
      ),
      milestoneRequestSubmitted: props.getMilestone(
        milestoneDefinitionKeys.REQUEST_SUBMITTED
      ),
    };

    //dossierdetails
    function dossierInformation() {
      return [
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? []
          : [
              {
                label: "Aangemaakt op",
                value: moment(get(props.dossier, ["createdAt"])).format(
                  "D MMMM YYYY"
                ),
              },
            ]),
        {
          label: "Status",
          value: status(milestonesForStatus),
        },
        {
          label: "Ingediend op",
          value: submitDate({
            requestSubmittedMilestone: props.getMilestone(
              milestoneDefinitionKeys.REQUEST_SUBMITTED
            ),
            requestDate: props.getFieldFromDocument(
              documentDefinitionKeys.REQUEST,
              ["aanvraagdatum"]
            ),
          }),
        },
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? [
              {
                label: "EAN en adres",
                value: eanNumberAndAdress({
                  eanNumber: props.getFieldFromDocument(
                    documentDefinitionKeys.REQUEST,
                    ["eanAndAddress", "eanCodeWoning"]
                  ),
                  adress: props.getFieldFromDocument(
                    documentDefinitionKeys.REQUEST,
                    ["uitvoeringsadres"]
                  ),
                }),
              },
            ]
          : []),
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? [
              {
                label: "Communicatie",
                value: communication({
                  communicationOption: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["manierVanOpDeHoogteBrengen", "selectedOption"]
                  ),
                  communicationOptionLabel: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["manierVanOpDeHoogteBrengen", "selectedOptionLabel", "nl"]
                  ),
                  otherOptionLabel: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    [
                      "manierVanOpDeHoogteBrengen",
                      "subfields",
                      "andereOptiesChoice",
                      "selectedOptionLabel",
                      "nl",
                    ]
                  ),
                  email: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["emailAdres"]
                  ),
                  phoneNumber: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["telefoonnummer"]
                  ),
                  isPostOption:
                    props.getFieldFromDocument(
                      documentDefinitionKeys.CONTACT_DATA,
                      [
                        "manierVanOpDeHoogteBrengen",
                        "subfields",
                        "andereOptiesChoice",
                        "selectedOption",
                      ]
                    ) === "post",
                  deliveryAdress: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    [
                      "manierVanOpDeHoogteBrengen",
                      "subfields",
                      "andereOptiesChoice",
                      "subfields",
                      "contactAdres",
                    ]
                  ),
                  mailAdressOtherParty: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["contactgegevensAnderePartij", "emailadresAnderePartij"]
                  ),
                }),
              },
            ]
          : []),
        ...(props.getMilestone(
          milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED
        ) &&
        accountNumber({
          belgischRekeningNummer: props.getFieldFromDocument(
            documentDefinitionKeys.CONTACT_DATA,
            ["accountnumber"]
          ),
          geenBelgischRekeningNummer: props.getFieldFromDocument(
            documentDefinitionKeys.CONTACT_DATA,
            ["geenBelgischRekeningnummer"]
          ),
          buitenlandsRekeningnummer: props.getFieldFromDocument(
            documentDefinitionKeys.CONTACT_DATA,
            ["buitenlandsRekeningnummer"]
          ),
        })
          ? [
              {
                label: "Rekeningnummer",
                value: accountNumber({
                  belgischRekeningNummer: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["accountnumber"]
                  ),
                  geenBelgischRekeningNummer: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["geenBelgischRekeningnummer"]
                  ),
                  buitenlandsRekeningnummer: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["buitenlandsRekeningnummer"]
                  ),
                }),
              },
            ]
          : []),
        ...(props.getFieldFromDocument(
          documentDefinitionKeys.WRP_GEGEVENS_KLANT_ANDERE_AANVRAGER,
          ["aanvraagPartij", "selectedOption"]
        ) === "energiehuis" &&
        props.getMilestone(milestoneDefinitionKeys.FETCH_DATA_CITIZEN)
          ? [
              {
                label: "Nummer Mijn VerbouwLening",
                value: energyLoan(),
              },
            ]
          : []),
        ...(props.getDocument(documentDefinitionKeys.LOGIN_DATA)
          ? [
              {
                label: "Aanvrager",
                value: applicant({
                  naamOrganisatie: props.getFieldFromDocument(
                    documentDefinitionKeys.LOGIN_DATA,
                    ["naamOrganisatie"]
                  ),
                  voornaam: props.getFieldFromDocument(
                    documentDefinitionKeys.LOGIN_DATA,
                    ["voornaam"]
                  ),
                  naam: props.getFieldFromDocument(
                    documentDefinitionKeys.LOGIN_DATA,
                    ["naam"]
                  ),
                  naamOrganisatieMandaatnemer: props.getFieldFromDocument(
                    documentDefinitionKeys.LOGIN_DATA,
                    ["naamOrganisatieMandaatnemer"]
                  ),
                }),
              },
            ]
          : []),
        ...(props.getFieldFromDocument(
          documentDefinitionKeys.WRP_DOSSIERINFORMATION,
          ["parentLabel"]
        )
          ? [
              {
                label: "Dossier basispremie",
                value: (
                  <div>
                    <>
                      <p
                        key="dossierNummerParent"
                        style={{ alignItems: "center" }}
                      >
                        <span style={{ fontWeight: "normal" }}>
                          Dossiernummer:{" "}
                        </span>
                        <span>
                          {props.getFieldFromDocument(
                            documentDefinitionKeys.WRP_DOSSIERINFORMATION,
                            ["parentLabel"]
                          )}
                        </span>
                      </p>
                    </>
                    <p
                      key="dossierStatusParent"
                      style={{ alignItems: "center" }}
                    >
                      <>
                        <span style={{ fontWeight: "normal" }}>Status: </span>
                      </>
                      <span>
                        {props.getFieldFromDocument(
                          documentDefinitionKeys.WRP_DOSSIERINFORMATION,
                          ["parentStatus", "selectedOptionLabel", "nl"]
                        )}
                      </span>
                    </p>
                  </div>
                ),
              },
            ]
          : [{}]),
        ...(props.is1700Employee &&
        props.getMilestone(milestoneDefinitionKeys.REQUEST_SUBMITTED)
          ? [
              {
                label: "Toegewezen aan",
                value: toegewezenAan(),
              },
            ]
          : []),
        // This is added to add some whitespace before the decision-table
        ...(!props.is1700Employee ? [] : [{}]),

        ...[{}],
        ...(props.getMilestone(
          milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT
        )
          ? [{ label: "Beslissing per categorie", value: categoryTable() }]
          : props.getMilestone(milestoneDefinitionKeys.REQUEST_SUBMITTED)
          ? [
              {
                label: "Categorieën van werken",
                value: categoryList({
                  categories: props.getFieldFromDocument(
                    documentDefinitionKeys.REQUEST,
                    [
                      "selectieCategorieen",
                      "geselecteerdeCatgeorieen",
                      "selectedOptions",
                    ]
                  ),
                  categoryLabels: map(
                    props.getFieldFromDocument(documentDefinitionKeys.REQUEST, [
                      "selectieCategorieen",
                      "geselecteerdeCatgeorieen",
                      "selectedOptionsLabels",
                    ]),
                    (labels) => labels.nl
                  ),
                }),
              },
            ]
          : []),
      ];
    }

    function energyLoan() {
      const energyLoan = props.getFieldFromDocument(
        documentDefinitionKeys.WRP_GEGEVENS_KLANT_ANDERE_AANVRAGER,
        ["nummerVanDeEnergielening"]
      );
      return energyLoan.substring(0, 10) + "-" + energyLoan.substring(10, 12);
    }

    function toegewezenAan() {
      return props.getFieldFromDocument(
        documentDefinitionKeys.WRP_DOSSIERINFORMATION,
        ["toegewezenAan", "selectedOptionLabel", "nl"]
      );
    }

    function categoryTable() {
      const categories = props.getFieldFromDocument(
        documentDefinitionKeys.REQUEST,
        ["selectieCategorieen", "geselecteerdeCatgeorieen", "selectedOptions"]
      );
      const categoryLabels = map(
        props.getFieldFromDocument(documentDefinitionKeys.REQUEST, [
          "selectieCategorieen",
          "geselecteerdeCatgeorieen",
          "selectedOptionsLabels",
        ]),
        (labels) => labels.nl
      );

      //Most recent amount is the amount of the first decision. Current is the amount that is calculated right now.
      function premiumAmount(categoryKey, isCurrentAmount) {
        let amount = 0;
        if (isCurrentAmount) {
          amount = props.getFieldFromDocument(
            documentDefinitionKeys.WRP_FINANCIAL_CONTROL,
            ["computations", premiumAmountFieldForCategory.get(categoryKey)]
          );
        } else {
          amount = props.getFieldFromDocument(
            documentDefinitionKeys.WRP_DOSSIERINFORMATION,
            [mostRecentPremiumAmountFieldForCategory.get(categoryKey)]
          );
        }
        if (!amount) amount = 0;
        return "€ " + amount;
      }

      function isAppealInProgress() {
        return (
          (props.getMilestone(
            milestoneDefinitionKeys.BEROEP_ATTEST_CONTROL_NEEDED
          ) ||
            props.getMilestone(
              milestoneDefinitionKeys.BEROEP_ATTEST_CONTROL_NOT_NEEDED
            ) ||
            props.getMilestone(
              milestoneDefinitionKeys.BEROEP_BACK_TO_TECHNICAL_CONTROL
            ) ||
            props.getMilestone(
              milestoneDefinitionKeys.BEROEP_BACK_TO_FINANCIAL_CONTROL
            )) &&
          !(
            props.getMilestone(
              milestoneDefinitionKeys.DOSSIER_DEF_APPROVED_BEROEP
            ) ||
            props.getMilestone(
              milestoneDefinitionKeys.DOSSIER_DEF_REJECTED_BEROEP
            )
          )
        );
      }

      function decisionPill(categoryKey) {
        const decisionForCategories = props.getFieldFromDocument(
          documentDefinitionKeys.WRP_DOSSIERINFORMATION,
          ["goedgekeurdeCategorieen", "selectedOptions"]
        );

        const decisionForCategoriesFirstLineTreatment =
          props.getFieldFromDocument(
            documentDefinitionKeys.WRP_DOSSIERINFORMATION,
            ["recentstGoedgekeurdeCategorieen", "selectedOptions"]
          );

        if (
          (isAppealInProgress() &&
            decisionForCategoriesFirstLineTreatment &&
            decisionForCategoriesFirstLineTreatment.includes(categoryKey) &&
            props.getMilestone(milestoneDefinitionKeys.DOSSIER_DEF_APPROVED)) ||
          (decisionForCategories &&
            decisionForCategories.includes(categoryKey) &&
            (props.getMilestone(milestoneDefinitionKeys.DOSSIER_DEF_APPROVED) ||
              props.getMilestone(
                milestoneDefinitionKeys.DOSSIER_DEF_APPROVED_BEROEP
              )))
        ) {
          return (
            <div className="vl-pill vl-pill--success">
              <span className="vl-pill__text">Goedgekeurd</span>
            </div>
          );
        } else {
          return (
            <div className="vl-pill vl-pill--error">
              <span className="vl-pill__text">Geweigerd</span>
            </div>
          );
        }
      }

      return (
        <table className="vl-data-table vl-data-table--">
          <thead>
            <tr>
              <th>Categorie</th>
              <th>Beslissing</th>
              <th>Premiebedrag</th>
            </tr>
          </thead>
          <tbody>
            {map(categories, (categoryKey, index) => (
              <tr key={categoryKey}>
                <td data-title="Categorie">
                  <p
                    className="vl-icon-wrapper"
                    style={{ alignItems: "center" }}
                  >
                    <span
                      className={classnames(
                        "vl-icon vl-icon--before",
                        categoryIcons[categoryKey]
                      )}
                      aria-hidden="true"
                    ></span>
                    <span>{categoryLabels[index]}</span>
                  </p>
                </td>
                <td data-title="Beslissing">{decisionPill(categoryKey)}</td>
                <td data-title="Premiebedrag">
                  {props.getMilestone(
                    milestoneDefinitionKeys.DOSSIER_DEF_APPROVED
                  ) ||
                  props.getMilestone(
                    milestoneDefinitionKeys.DOSSIER_DEF_APPROVED_BEROEP
                  )
                    ? isAppealInProgress()
                      ? premiumAmount(categoryKey, false)
                      : premiumAmount(categoryKey, true)
                    : "€ 0"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    function downloads() {
      const downloads = [];

      // "condition": what has to be true to show the communication
      // "communication": the name of the communication
      // "displayName": what name has to be shown for this communication

      const communicationsToAdd = [
        {
          condition:
            props.getCommunication("beslissingsbriefBeroep") &&
            props.getMilestone(
              milestoneDefinitionKeys.DECISION_LETTER_BEROEP_ONGEGROND_TO_CLIENT
            ),
          communication: "beslissingsbriefBeroep",
          displayName: "Beslissingsbrief beroep",
        },
        {
          condition:
            (props.getMilestone(
              milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT
            ) &&
              !props.getMilestone(milestoneDefinitionKeys.BEROEP_STARTED) &&
              !props.getMilestone(milestoneDefinitionKeys.PARENT_REJECTED)) ||
            props.getMilestone(
              milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT_BEROEP
            ),
          communication: "beslissingsbrief",
          displayName: "Beslissingsbrief",
        },
        {
          condition:
            props.getMilestone(
              milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT
            ) && props.getMilestone(milestoneDefinitionKeys.PARENT_REJECTED),
          communication: "weigeringsbriefChild",
          displayName: "Beslissingsbrief",
        },
        {
          condition: props.getMilestone(
            milestoneDefinitionKeys.DETAIL_DECISION_LETTER_SENT
          ),
          communication: "detailBeslissing",
          displayName: "Detail beslissing",
        },
        {
          condition:
            props.getMilestone(
              milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED
            ) ||
            props.getMilestone(
              milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP
            ),
          communication: "bijkomendeStukken",
          displayName: "Bijkomende stukken aan te leveren",
        },
        {
          condition: props.getCommunication("ontvangstbevestigingBeroep"),
          communication: "ontvangstbevestigingBeroep",
          displayName: "Ontvangstbevestiging beroep",
        },
        {
          condition: props.getCommunication("ontvangstbevestiging"),
          communication: "ontvangstbevestiging",
          displayName: "Ontvangstbevestiging",
        },
        {
          condition: props.getCommunication(
            "ontvangstbevestigingBeroepTegenStilzitten"
          ),
          communication: "ontvangstbevestigingBeroepTegenStilzitten",
          displayName: "Ontvangstbevestiging beroep tegen stilzitten",
        },
        {
          condition: props.getMilestone(
            milestoneDefinitionKeys.COMMUNICATION_TO_REQUESTOR_INITIATED
          ),
          communication: "losseCommunicatie",
          displayName: "Communicatie over uw dossier",
        },
        {
          condition: props.getMilestone(
            milestoneDefinitionKeys.CHANGE_OF_REQUESTOR_REQUESTED
          ),
          communication: "wijzigingAanvrager",
          displayName: "Voorstel wijzigen aanvrager",
        },
      ];

      communicationsToAdd.forEach(
        ({ condition, communication, displayName }) => {
          if (condition) {
            downloads.push(
              props.mapCommunicationToPdfDownloadInfo(
                props.getCommunication(communication),
                displayName
              )
            );
          }
        }
      );

      // "AanvraagOverzicht" is an exception and has to be added in another way
      if (props.getMilestone(milestoneDefinitionKeys.REQUEST_SUBMITTED)) {
        downloads.push(
          props.mapDocumentToPdfDownloadInfo(
            props.getDocument(documentDefinitionKeys.REQUEST),
            "Aanvraag",
            "aanvraagOverzicht"
          )
        );
      }

      props.downloads.forEach((download) => downloads.push(download));

      return downloads;
    }

    function getActiveTasks() {
      if (props.loadingDossierDetails) return;
      const allTasks = get(props, ["dossierDetails", "task"], []);
      return allTasks.filter(function (task) {
        return task.active;
      });
    }

    function executeTask(taskId) {
      const assignee = {
        sub: props.currentUser.sub,
        username: props.currentUser.username,
        active: props.currentUser.active,
      };
      // Best-effort implementation where we try to change the
      // assignee in every case and rely on the fine-grained authz
      // to figure out if the current user is actually allowed to do so.
      // Redirect to the task regardless of the assignment being succesful.
      return props.changeTaskAssignee(taskId, assignee).finally(() => {
        router.goToState(pageNames.TASK, { id: taskId });
      });
    }

    const propsToPass = {
      ...props,
      loaderLabel,
      loadingDossierDetails:
        props.loadingDossierDetails ||
        loadingDossierDetailsAfterMilestonesInterval,
      contactInformation:
        contactInformation[props.dossier.dossierDefinitionKey],
      notifications: notifications.notifications({
        notification: getNotificationForLatestMilestone(),
        values: valuesForNotifications,
        dossierType,
      }),
      dossierInformation: dossierInformation(),
      downloads: downloads(),
      getMyActiveTasksInDossier: getActiveTasks,
      executeTask: executeTask,
    };

    function downloadBijkomendeStukkenPdf() {
      let communication = props?.getCommunication("bijkomendeStukken");
      if (communication) {
        props.exportToPdfCommunication(
          communication?.id,
          `${props?.dossier?.label} - bijkomendeStukken`
        );
      }
    }

    useEffect(() => {
      const addClickHandler = () => {
        const anchorElement = document.getElementById(
          "download-bijkomende-stukken"
        );

        // Check if the event listener is already added to avoid duplication
        if (anchorElement && !anchorElement.dataset.listenerAdded) {
          anchorElement.addEventListener("click", (event) => {
            event.preventDefault(); // Prevent default anchor behavior
            downloadBijkomendeStukkenPdf();
          });
          // Mark this element to indicate the event listener has been added
          anchorElement.dataset.listenerAdded = "true";
        }
      };

      const observer = new MutationObserver(() => {
        addClickHandler();
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });

      // Initial check in case elements are already present
      addClickHandler();

      // Cleanup observer on unmount
      return () => {
        observer.disconnect();
      };
    }, []);

    return (
      <>
        <CoreDossierPage {...propsToPass} />
      </>
    );
  }

  MvpDossierPage.propTypes = {
    ...externalProps,
    ...internalProps,
    dossier: PropTypes.shape({
      ...externalProps.dossier,
      dossierDefinitionKey: PropTypes.string,
    }),
  };

  return MvpDossierPage;
}

const mapStateToProps = (state) => ({
  is1700Employee: is1700Employee(state),
});

export default coreDossierPageWrapper(
  connect(mapStateToProps, { fetchWrpProfile })(mvpDossierPageWrapper())
);
