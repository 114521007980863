import React from "react";

import CoreTaskPage from "@skryv/core-react-vo/src/components/task/TaskPage/TaskPage";

export default function wrpTaskPage(props) {
  return (
    <CoreTaskPage
      {...props}
      shouldShowTitle={false}
      shouldShowReviewWarning={false}
      shouldShowCancelButton={false} // we add a custom "cancel request" button to the SectionWizard, so we need to disable this one
    ></CoreTaskPage>
  );
}
