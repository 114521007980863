export const FETCH_ADDRESS_BY_EAN_CODE = "FETCH_ADDRESS_BY_EAN_CODE";
export const FETCH_CUMUL_CATEGORIES_BY_AO = "FETCH_CUMUL_CATEGORIES_BY_AO";
export const FETCH_TOGGLE_APPARTEMENTEN = "FETCH_TOGGLE_APPARTEMENTEN";
const defaultSize = 10;

export const fetchAddressByEanCode = (ean) => {
  return {
    type: FETCH_ADDRESS_BY_EAN_CODE,
    api: {
      url: `api/fluvius/data?ean=${ean}`,
      method: "GET",
    },
  };
};

export const fetchCumulCategoriesByAo = (
  aansluitObjectId,
  superAansluitObjectId
) => {
  const params = {};
  if (aansluitObjectId && aansluitObjectId != undefined)
    params.aansluitObjectId = aansluitObjectId;
  if (superAansluitObjectId && superAansluitObjectId != undefined)
    params.superAansluitObjectId = superAansluitObjectId;

  // parameters will be aansluitObjectId=${aansluitObjectId}superAansluitObjectId=${superAansluitObjectId}
  const parameters = new URLSearchParams(params).toString();

  return {
    type: FETCH_CUMUL_CATEGORIES_BY_AO,
    api: {
      url: `api/fluvius/data/parents?${parameters}`,
      method: "GET",
    },
  };
};

export const fetchToggleAppartementen = () => {
  return {
    type: FETCH_TOGGLE_APPARTEMENTEN,
    api: {
      url: `api/fluvius/toggle`,
      method: "GET",
    },
  };
};

const sortOptions = {
  "dossier.latestActivity": {
    order: "desc",
    nested: { path: "dossier" },
  },
};

export function filterSecureDashboardDossiers(
  searchQuery,
  pageNumber = 0,
  itemsPerPage = defaultSize
) {
  const query = {
    query: {
      bool: {
        filter: [
          {
            nested: {
              path: "dossier",
              query: {
                bool: {
                  must: [{ query_string: { query: `*${searchQuery}*` } }],
                },
              },
            },
          },
          { bool: { should: [{ term: { type: "dossier" } }] } },
        ],
      },
    },
    from: pageNumber * itemsPerPage,
    size: itemsPerPage,
    sort: sortOptions,
  };
  return fetchSecureDossiers({ listKey: "DASHBOARD", query });
}

export function fetchSecureDashboardDossiers(
  pageNumber = 0,
  itemsPerPage = defaultSize
) {
  const query = {
    from: pageNumber * defaultSize,
    query: {
      bool: {
        filter: [{ bool: { should: [{ term: { type: "dossier" } }] } }],
      },
    },
    size: itemsPerPage,
    sort: sortOptions,
  };
  return fetchSecureDossiers({ listKey: "DASHBOARD", query });
}

export const FETCH_SECURE_DOSSIERS = "FETCH_SEARCH_DOSSIERS_WRP";
export const FETCH_SECURE_DOSSIERS_RESPONSE = `${FETCH_SECURE_DOSSIERS}/RESPONSE`;
export const FETCH_SECURE_DOSSIERS_ERROR = `${FETCH_SECURE_DOSSIERS}/ERROR`;
export function fetchSecureDossiers({ query, listKey } = {}) {
  if (!query) {
    query = {
      from: 0,
      query: {
        bool: {
          filter: [{ bool: { should: [{ term: { type: "dossier" } }] } }],
        },
      },
      size: defaultSize,
      sort: {
        "dossier.latestActivity": {
          order: "desc",
          nested: { path: "dossier" },
        },
      },
    };
  }
  return {
    type: FETCH_SECURE_DOSSIERS,
    listKey,
    api: {
      url: "api/wrp-dossiers/search",
      method: "POST",
      listKey,
      data: query,
    },
  };
}

export const FETCH_EBOX_STATUS = "FETCH_EBOX_STATUS";
export const fetchEboxStatus = (dossierId) => {
  return {
    type: FETCH_EBOX_STATUS,
    api: {
      url: `/api/ebox/data?dossierId=${dossierId}`,
      method: "GET",
    },
  };
};

export const FETCH_WRP_PROFILE = "FETCH_WRP_PROFILE";
export const FETCH_WRP_PROFILE_RESPONSE = `${FETCH_WRP_PROFILE}/RESPONSE`;
export const fetchWrpProfile = () => {
  return {
    type: FETCH_WRP_PROFILE,
    api: {
      url: `/api/wrp/profile`,
      method: "GET",
    },
  };
};

export const FETCH_BURGERPROFIEL_CONFIGURATION =
  "FETCH_BURGERPROFIEL_CONFIGURATION";
export const FETCH_BURGERPROFIEL_CONFIGURATION_RESPONSE = `${FETCH_BURGERPROFIEL_CONFIGURATION}/RESPONSE`;
export const FETCH_BURGERPROFIEL_CONFIGURATION_ERROR = `${FETCH_BURGERPROFIEL_CONFIGURATION}/ERROR`;
export const fetchBurgerprofielConfiguration = () => {
  return {
    type: FETCH_BURGERPROFIEL_CONFIGURATION,
    api: {
      url: "/api/burgerprofiel/configuration",
      method: "GET",
    },
  };
};

export const FETCH_FEATURE_TOGGLES = "FETCH_FEATURE_TOGGLES";
export const FETCH_FEATURE_TOGGLES_RESPONSE = `${FETCH_FEATURE_TOGGLES}/RESPONSE`;
export const FETCH_FEATURE_TOGGLES_ERROR = `${FETCH_FEATURE_TOGGLES}/ERROR`;
export const fetchFeatureToggles = () => {
  return {
    type: FETCH_FEATURE_TOGGLES,
    api: {
      url: "/api/feature-toggles",
      method: "GET",
    },
  };
};

const STATUS_PAGE_ID = "vgktpzt62pp3";
const FETCH_STATUS_UNRESOLVED_INCIDENTS = "FETCH_STATUS_UNRESOLVED_INCIDENTS";
const FETCH_STATUS_UPCOMING_MAINTENANCE = "FETCH_STATUS_UPCOMING_MAINTENANCE";
const FETCH_STATUS_ACTIVE_MAINTENANCE = "FETCH_STATUS_ACTIVE_MAINTENANCE";
export const FETCH_STATUS_UNRESOLVED_INCIDENTS_RESPONSE = `${FETCH_STATUS_UNRESOLVED_INCIDENTS}/RESPONSE`;
export const FETCH_STATUS_UPCOMING_MAINTENANCE_RESPONSE = `${FETCH_STATUS_UPCOMING_MAINTENANCE}/RESPONSE`;
export const FETCH_STATUS_ACTIVE_MAINTENANCE_RESPONSE = `${FETCH_STATUS_ACTIVE_MAINTENANCE}/RESPONSE`;

export const fetchUnresolvedIncidents = () => {
  return {
    type: FETCH_STATUS_UNRESOLVED_INCIDENTS,
    api: {
      url: `https://${STATUS_PAGE_ID}.statuspage.io/api/v2/incidents/unresolved.json`,
      method: "GET",
    },
  };
};

export const fetchUpcomingMaintenance = () => {
  return {
    type: FETCH_STATUS_UPCOMING_MAINTENANCE,
    api: {
      url: `https://${STATUS_PAGE_ID}.statuspage.io/api/v2/scheduled-maintenances/upcoming.json`,
      method: "GET",
    },
  };
};

export const fetchActiveMaintenance = () => {
  return {
    type: FETCH_STATUS_ACTIVE_MAINTENANCE,
    api: {
      url: `https://${STATUS_PAGE_ID}.statuspage.io/api/v2/scheduled-maintenances/active.json`,
      method: "GET",
    },
  };
};
