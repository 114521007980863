import React from "react";

import useFieldSet, {
  containerComponentProps,
} from "@skryv/core-react/src/components/form/editor/FieldSet/fieldSetHelpers";
import FieldSetInput from "@skryv/core-react-vo/src/components/base/InputFields/FieldSet/FieldSet";
import EditorGroup from "@skryv/core-react-vo/src/components/form/components/wrappers/EditorGroup/EditorGroup";
import EditorComponent from "@skryv/core-react-vo/src/components/form/components/wrappers/EditorComponent/EditorComponent";

function AsSection(props) {
  const propsToPass = useFieldSet(props, EditorComponent);

  if (!props.readOnly) {
    return <EditorGroup {...props} />;
  } else {
    return (
      <FieldSetInput {...propsToPass}>{propsToPass.children()}</FieldSetInput>
    );
  }
}

AsSection.propTypes = containerComponentProps;

export default AsSection;
