import { filter, get, map, reduce, some } from "lodash";

import { doSubFieldsHaveErrors } from "@skryv/core-react/src/core/services/docmod/documentModel";

export function doSubFieldsHaveErrorsOtherThanRequired(manipulator) {
  if (!manipulator.isActive()) return false;
  else if (
    manipulator.errors &&
    manipulator.errors.length > 0 &&
    filter(manipulator.errors, (error) => error !== "required").length > 0
  )
    return true;
  else if (manipulator.nestedManipulators) {
    return some(manipulator.nestedManipulators, (nestedManipulator) =>
      doSubFieldsHaveErrorsOtherThanRequired(nestedManipulator)
    );
  }
  return false;
}

// section = component.sections[...]
// manipulator = root
export function doesSectionHaveErrors(section, manipulator) {
  function reducer(allManipulators) {
    return reduce(
      allManipulators,
      (acc, current) => {
        return acc || !!doSubFieldsHaveErrors(current);
      },
      false
    );
  }

  return loopOverManipulatorsInSection(section, manipulator, reducer);
}

// section = component.sections[...]
// manipulator = root
export function doesSectionHaveErrorsOtherThanRequired(section, manipulator) {
  function reducer(allManipulators) {
    return reduce(
      allManipulators,
      (acc, current) => {
        return acc || !!doSubFieldsHaveErrorsOtherThanRequired(current);
      },
      false
    );
  }

  return loopOverManipulatorsInSection(section, manipulator, reducer);
}

// section = component.sections[...]
// manipulator = root
function loopOverManipulatorsInSection(section, manipulator, reducer) {
  if (!manipulator.isActive()) return reducer([]);
  const allFieldsInSection = map(section.components, (component) =>
    get(component, ["inputOptions", "name"])
  );
  const allManipulatorsInSection = map(allFieldsInSection, (fieldName) =>
    get(manipulator, ["propertyManipulators", fieldName])
  );
  return reducer(allManipulatorsInSection);
}

export function calculateWhetherSectionShouldBeDisabled(sections) {
  let previousSectionsAreComplete = true;
  return map(sections, (section, index, allSections) => {
    if (index === 0 && !section.isExtraForContext) return section;

    if (section.isExtraForContext === true) {
      return {
        ...section,
        isDisabled: true,
      };
    } else {
      previousSectionsAreComplete =
        previousSectionsAreComplete &&
        allSections[index - 1].isComplete &&
        (!allSections[index - 1].subSections ||
          allSections[index - 1].allSubSectionsComplete);
      if (section.subSections) {
        const superSectionPreviousSectionsAreComplete =
          previousSectionsAreComplete;
        const updatedSubSections = map(
          section.subSections,
          (subsection, subindex, allSubsections) => {
            if (subindex === 0) {
              previousSectionsAreComplete =
                previousSectionsAreComplete && allSections[index].isComplete;
              return {
                ...subsection,
                isDisabled: !previousSectionsAreComplete,
              };
            } else {
              previousSectionsAreComplete =
                previousSectionsAreComplete &&
                allSubsections[subindex - 1].isComplete;
              return {
                ...subsection,
                isDisabled: !previousSectionsAreComplete,
              };
            }
          }
        );
        return {
          ...section,
          isDisabled: !superSectionPreviousSectionsAreComplete,
          subSections: updatedSubSections,
        };
      }
      return { ...section, isDisabled: !previousSectionsAreComplete };
    }
  });
}

export function goToFirstErrorInputOnThePage() {
  function findEditorComponentFromErrorMessage(element) {
    if (!element) {
      return;
    } else if (element.classList.contains("editor-component")) {
      return element;
    } else {
      return findEditorComponentFromErrorMessage(element.parentElement);
    }
  }

  const editorComponent = findEditorComponentFromErrorMessage(
    document.getElementsByClassName("vl-form__error")[0]
  );

  if (editorComponent) {
    const input = editorComponent.querySelector("input");
    input && input.focus();
    if (input) return; // all done
  }

  // in case of a modal table with an error in a subfield, we will not be able to find a related input field
  const tableRow = document.getElementsByClassName(
    "vl-data-table__element--error"
  )[0];

  // select the edit button
  if (tableRow) {
    const button = tableRow.querySelector("button");
    button && button.focus();
  }
}
