import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

import { notificationProps } from "@skryv/core-react/src/components/base/Notification/Notification";
import Notification from "@skryv/core-react-vo/src/components/base/Notification/Notification";

export default function IncidentNotifications({ incidents }) {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {incidents &&
        incidents.length > 0 &&
        map(incidents, (incident) => (
          <div
            style={{
              marginTop: "2rem",
            }}
            key={incident.id}
          >
            <Notification notification={incident} key={incident.id} />
          </div>
        ))}
    </div>
  );
}

IncidentNotifications.propTypes = {
  incidents: PropTypes.arrayOf(notificationProps),
};
