export default {
  technischeInstallaties: "wrp-icon-technische-installaties",
  binnenrenovatie: "wrp-icon-binnenrenovatie",
  dakrenovatie: "wrp-icon-dakrenovatie",
  muurrenovatie: "wrp-icon-muurrenovatie",
  vloerrenovatie: "wrp-icon-vloerrenovatie",
  buitenschrijnwerkGlas: "wrp-icon-buitenschrijnwerk",
  warmtepomp: "wrp-icon-warmtepomp",
  warmtepompboiler: "wrp-icon-warmtepompboiler",
  zonneboiler: "wrp-icon-zonneboiler",
  gascondensatieketel: "wrp-icon-gascondensatieketel",
  zonnepanelen: "wrp-icon-zonnepanelen",
};
