import React from "react";
import {
  dossierPageWrapper as coreDossierPageWrapper,
  externalProps,
  internalProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import { DossierPage as CoreDossierPage } from "@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage";
import { connect } from "react-redux";
import { fetchWrpProfile } from "../../../store/actions";
import { is1700Employee } from "../../../store/selectors";
import contactInformation from "../../../constants/contactInformation";
import PropTypes from "prop-types";

export function defaultDossierPageWrapper() {
  function DefaultDossierPage(props) {
    const propsToPass = {
      ...props,
      // loaderLabel,
      //loadingDossierDetails:
      // props.loadingDossierDetails ||
      //loadingDossierDetailsAfterMilestonesInterval,
      contactInformation:
        contactInformation[props.dossier.dossierDefinitionKey] ||
        contactInformation["default"],
      //notifications: notifications(),
      //dossierInformation: dossierInformation(),
      //downloads: downloads(),
      //getMyActiveTasksInDossier: getActiveTasks,
      //executeTask: executeTask,
    };

    return (
      <>
        <CoreDossierPage {...propsToPass} />
      </>
    );
  }

  DefaultDossierPage.propTypes = {
    ...externalProps,
    ...internalProps,
    dossier: PropTypes.shape({
      ...externalProps.dossier,
      dossierDefinitionKey: PropTypes.string,
    }),
  };

  return DefaultDossierPage;
}

const mapStateToProps = (state) => ({
  is1700Employee: is1700Employee(state),
});

export default coreDossierPageWrapper(
  connect(mapStateToProps, { fetchWrpProfile })(defaultDossierPageWrapper())
);
