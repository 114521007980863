import React from "react";
import useFieldSet, {
  containerComponentProps,
} from "@skryv/core-react/src/components/form/editor/FieldSet/fieldSetHelpers";
import FieldSetBase from "@skryv/core-react-vo/src/components/base/InputFields/FieldSet/FieldSet";
import EditorComponent from "@skryv/core-react-vo/src/components/form/components/wrappers/EditorComponent/EditorComponent";

export default function FieldSet(props) {
  const propsToPass = useFieldSet(props, EditorComponent);
  return (
    <FieldSetBase {...propsToPass} nested={true}>
      {propsToPass.children()}
    </FieldSetBase>
  );
}

FieldSet.propTypes = containerComponentProps;
