import React from "react";
import PropTypes from "prop-types";

import EditorComponent from "@skryv/core-react-vo/src/components/form/components/wrappers/EditorComponent/EditorComponent";

import "./WRPFormSummary.scss";

function WRPFormSummary(props) {
  return (
    <div className="wrp-form-summary">
      <EditorComponent {...props} readOnly={true} nested={true} />
    </div>
  );
}

WRPFormSummary.propTypes = {
  component: PropTypes.shape({}).isRequired,
  manipulator: PropTypes.shape({}).isRequired,
  config: PropTypes.shape({}).isRequired,
  nested: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default WRPFormSummary;
