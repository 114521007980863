import { get } from "lodash";
import { notificationTypes } from "@skryv/core-react/src/components/base/Notification/Notification";

const impactToNotificationType = {
  critical: notificationTypes.ERROR,
  major: notificationTypes.WARNING,
  minor: notificationTypes.INFO,
  none: notificationTypes.INFO,
};

export function mapIncidentToNotification(incident) {
  return {
    message: get(incident, ["incident_updates", 0, "body"]),
    title: get(incident, ["name"]),
    type:
      impactToNotificationType[get(incident, ["impact"])] ||
      notificationTypes.WARNING,
    id: get(incident, ["id"]),
  };
}
