import immutable from "seamless-immutable";
import get from "lodash/get";
import map from "lodash/map";
import {
  FETCH_BURGERPROFIEL_CONFIGURATION_ERROR,
  FETCH_BURGERPROFIEL_CONFIGURATION_RESPONSE,
  FETCH_SECURE_DOSSIERS_ERROR,
  FETCH_SECURE_DOSSIERS_RESPONSE,
  FETCH_WRP_PROFILE_RESPONSE,
  FETCH_STATUS_UNRESOLVED_INCIDENTS_RESPONSE,
  FETCH_STATUS_UPCOMING_MAINTENANCE_RESPONSE,
  FETCH_STATUS_ACTIVE_MAINTENANCE_RESPONSE,
  FETCH_FEATURE_TOGGLES_RESPONSE,
  FETCH_FEATURE_TOGGLES_ERROR,
} from "./actions";

export const burgerprofielConfiguration = (
  state = immutable({ loaded: false }),
  action
) => {
  switch (action.type) {
    case FETCH_BURGERPROFIEL_CONFIGURATION_RESPONSE:
      return immutable({ loaded: true, configuration: action.response.data });
    case FETCH_BURGERPROFIEL_CONFIGURATION_ERROR:
      return immutable({ loaded: true });
    default:
      return state;
  }
};

export const featureToggles = (
  state = immutable({
    loaded: false,
    toggles: {
      activateMijnVerbouwLening: false,
      activateEpcLabelpremie: false,
    },
  }),
  action
) => {
  switch (action.type) {
    case FETCH_FEATURE_TOGGLES_RESPONSE:
      return immutable({ loaded: true, toggles: action.response.data });
    case FETCH_FEATURE_TOGGLES_ERROR:
      return immutable({ loaded: true });
    default:
      return state;
  }
};

export const search = (state = immutable({}), action) => {
  switch (action.type) {
    case FETCH_SECURE_DOSSIERS_RESPONSE:
      const hit = get(action, "response.data.hits");

      state = !hit
        ? state
        : state.merge(
            hit.reduce(
              (
                result,
                {
                  createdAt,
                  dossierDefinitionLabel,
                  id,
                  dossierLabel,
                  tasks,
                  uitvoeringsadres,
                  aanvrager,
                  aanvraagDatum,
                  milestones,
                }
              ) => {
                return result.merge(
                  {
                    dossiers: {
                      byId: {
                        [id]: {
                          createdAt,
                          dossierDefinitionLabel,
                          id,
                          dossierLabel,
                          uitvoeringsadres,
                          submit: {
                            aanvraagDatum,
                            uitvoeringsadres,
                          },
                          aanvrager,
                          aanvraagDatum,
                          label: dossierLabel,
                          milestones,
                          dossierDefinition: { label: dossierDefinitionLabel },
                        },
                      },
                    },
                    tasks: {
                      byId: {
                        [id]: tasks,
                      },
                    },
                  },
                  { deep: true }
                );
              },
              immutable({})
            )
          );

      if (!hit) {
        return state;
      }
      const ids = map(hit, "id");
      const total = get(action, "response.data.total");

      return state.merge(
        { byKey: { ["DASHBOARD"]: { ids, total } } },
        { deep: true }
      );

    case FETCH_SECURE_DOSSIERS_ERROR: {
      let key = action.listKey;
      const status = get(action, "error.status");
      if (status === 404 || status === 500) {
        return state.merge({ byKey: { [key]: { ids: [], total: 0 } } });
      }
      return state;
    }

    default:
      return state;
  }
};

export const wrpProfile = (state = immutable({}), action) => {
  if (action.type === FETCH_WRP_PROFILE_RESPONSE) {
    return immutable({ profile: action.response.data });
  }

  return state;
};

export const statusPage = (state = immutable({}), action) => {
  switch (action.type) {
    case FETCH_STATUS_UNRESOLVED_INCIDENTS_RESPONSE:
      return state.set("incidents", action.response.data.incidents);
    case FETCH_STATUS_UPCOMING_MAINTENANCE_RESPONSE:
      return state.set(
        "scheduled_maintenances",
        action.response.data.scheduled_maintenances
      );
    case FETCH_STATUS_ACTIVE_MAINTENANCE_RESPONSE:
      return state.set(
        "active_maintenances",
        action.response.data.scheduled_maintenances
      );
    default:
      return state;
  }
};
