import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

export function selectBurgerprofielHeader(state) {
  return get(state, [
    "burgerprofielConfiguration",
    "configuration",
    "headerEmbedCode",
  ]);
}

export function selectBurgerprofielFooter(state) {
  return get(state, [
    "burgerprofielConfiguration",
    "configuration",
    "footerEmbedCode",
  ]);
}

export function hasLoadedBurgerprofiel(state) {
  return get(state, ["burgerprofielConfiguration", "loaded"]);
}

export function hasValidBurgerprofielFooter(state) {
  return !isEmpty(
    get(state, [
      "burgerprofielConfiguration",
      "configuration",
      "footerEmbedCode",
    ])
  );
}

export function hasValidBurgerprofielHeader(state) {
  return !isEmpty(
    get(state, [
      "burgerprofielConfiguration",
      "configuration",
      "headerEmbedCode",
    ])
  );
}

export function selectFeatureToggles(state) {
  return get(state, ["featureToggles", "toggles"]);
}

export function hasLoadedFeatureToggles(state) {
  return get(state, ["featureToggles", "loaded"]);
}

export function selectSecureDossier(state, id) {
  const dossier = get(state, ["search", "dossiers", "byId", id]);
  if (!dossier) return;
  const tasks = get(state, ["search", "tasks", "byId", id]);

  return Object.assign({}, dossier, {
    tasks,
  });
}

export function selectSecureDossierList(state, listKey) {
  const ids = get(state, ["search", "byKey", listKey, "ids"]);
  if (!ids) return;
  return ids.map((id) => selectSecureDossier(state, id));
}

export function selectSecureDossierListCount(state, listKey) {
  if (!listKey) return 0;
  return get(state, ["search", "byKey", listKey, "total"]);
}

export function is1700Employee(state) {
  return get(state, ["wrpProfile", "profile", "employeeOf1700"], false);
}

export function selectUnresolvedIncidents(state) {
  return get(state, ["statusPage", "incidents"]);
}

export function selectScheduledMaintenances(state) {
  return get(state, ["statusPage", "scheduled_maintenances"]);
}

export function selectActiveMaintenances(state) {
  return get(state, ["statusPage", "active_maintenances"]);
}
