import React, { useContext } from "react";
import { get, map } from "lodash";
import classnames from "classnames";

import { TranslationsContext } from "@skryv/core-react/src/services/translations";
import useFieldSet, {
  containerComponentProps as fieldSetContainerComponentProps,
} from "@skryv/core-react/src/components/form/editor/FieldSet/fieldSetHelpers";

import useAttachmentsList, {
  containerComponentProps as attachmentListContainerComponentProps,
} from "@skryv/core-react/src/components/form/editor/AttachmentsList/attachmentsListHelpers";
import InputField from "@skryv/core-react-vo/src/components/base/InputFields/InputField/InputField";
import AttachmentsList from "@skryv/core-react-vo/src/components/base/InputFields/AttachmentsList/AttachmentsList";
import EditorComponent from "@skryv/core-react-vo/src/components/form/components/wrappers/EditorComponent/EditorComponent";

import categoryIcons from "../../../constants/iconsForCategories";

import "./AttachmentsTable.scss";

function AttachmentsTable({ component, manipulator, ...otherProps }) {
  const { gettext } = useContext(TranslationsContext);
  const {
    id,
    name,
    label,
    isRequired,
    help,
    helpInLine,
    errorMessages = [],
    warningMessages = [],
  } = useFieldSet({ component, manipulator, ...otherProps }, () => {});

  return (
    <InputField
      id={id}
      name={name}
      label={label}
      isRequired={isRequired}
      helpInLine={helpInLine}
      help={help}
      errorMessages={errorMessages}
      warningMessages={warningMessages}
      shouldUseFullWidth={true}
    >
      <table className="attachments-table vl-data-table vl-data-table--collapsed-s">
        <thead>
          <tr>
            <th>{gettext("Attachment to upload")}</th>
            <th>{gettext("Upload")}</th>
          </tr>
        </thead>
        <tbody>
          {map(
            component.components.map((nestedChild, i) => {
              return (
                nestedChild.nestedManipulator(manipulator).isActive() && (
                  <AttachmentsTableRow
                    component={nestedChild}
                    manipulator={nestedChild.nestedManipulator(manipulator)}
                    {...otherProps}
                    key={i}
                  />
                )
              );
            })
          )}
        </tbody>
      </table>
    </InputField>
  );
}

AttachmentsTable.propTypes = fieldSetContainerComponentProps;

export default AttachmentsTable;

function AttachmentsTableRow(props) {
  const { gettext } = useContext(TranslationsContext);

  const {
    id,
    label,
    isRequired,
    readOnly,
    helpInLine,
    help,
    errorMessages,
    warningMessages,
    elements,
    ...otherPropsForAttachmentsList
  } = useAttachmentsList(props, EditorComponent);

  const propsForInputField = {
    id,
    label,
    isRequired,
    readOnly,
    helpInLine,
    help,
    errorMessages,
    warningMessages,
  };

  const categoryIconsForAttachmentKeys = {
    dakisolatie: categoryIcons.dakrenovatie,
    asbestverwijderingDak: categoryIcons.dakrenovatie,
    muurisolatie: categoryIcons.muurrenovatie,
    asbestverwijderingMuur: categoryIcons.muurrenovatie,
    vloerisolatie: categoryIcons.vloerrenovatie,
    beglazing: categoryIcons.buitenschrijnwerkGlas,
    plaatsingWarmtepomp: categoryIcons.warmtepomp,
    plaatsingWarmtepompboiler: categoryIcons.warmtepompboiler,
    plaatsingZonneboiler: categoryIcons.zonneboiler,
    plaatsingGascondensatieketel: categoryIcons.gascondensatieketel,
    keuringsattestGascondensatieketel: categoryIcons.gascondensatieketel,
    bewijsIsolatieDakrenovatie: categoryIcons.dakrenovatie,
    bewijsVloerisolatie: categoryIcons.vloerrenovatie,
    kopieProductlabelNieuwGeplaatsteWarmtepomp: categoryIcons.warmtepomp,
    kopieProductlabelNieuwGeplaatsteWarmtepompboiler:
      categoryIcons.warmtepompboiler,
    bewijsActieveKoelingWarmtepomp: categoryIcons.warmtepomp,
    bewijsMinimaleEnergieEfficientieWarmtepomp: categoryIcons.warmtepomp,
    bewijsVentilatieRamen: categoryIcons.buitenschrijnwerkGlas,
    fotoBeglazing: categoryIcons.buitenschrijnwerkGlas,
    bewijsPvOfZb: categoryIcons.dakrenovatie,
    fotoCollectoren: categoryIcons.zonneboiler,
    attestSts: categoryIcons.muurrenovatie,
    grondplan: categoryIcons.muurrenovatie,
    keuringsattestElektriciteit: categoryIcons.technischeInstallaties,
    attestMuurisolatie: categoryIcons.muurrenovatie,
    attestAsbestverwijderingMuren: categoryIcons.muurrenovatie,
    attestDakisolatie: categoryIcons.dakrenovatie,
    attestAsbestverwijderingDak: categoryIcons.dakrenovatie,
    attestPlaatsingGascondensatieketel: categoryIcons.gascondensatieketel,
    attestBeglazing: categoryIcons.buitenschrijnwerkGlas,
    attestVloerisolatie: categoryIcons.vloerrenovatie,
    attestPlaatsingWarmtepomp: categoryIcons.warmtepomp,
    attestPlaatsingWarmtepompboiler: categoryIcons.warmtepompboiler,
    attestPlaatsingZonneboiler: categoryIcons.zonneboiler,
    offerteBinnenrenovatie: categoryIcons.binnenrenovatie,
    offerteBuitenmuur: categoryIcons.muurrenovatie,
    offerteDak: categoryIcons.dakrenovatie,
    offerteElektriciteitEnSanitair: categoryIcons.technischeInstallaties,
    offerteGascondensatieketel: categoryIcons.gascondensatieketel,
    offerteRamenEnDeuren: categoryIcons.buitenschrijnwerkGlas,
    offerteVloer: categoryIcons.vloerrenovatie,
    offerteWarmtepomp: categoryIcons.warmtepomp,
    offerteWarmtepompboiler: categoryIcons.warmtepompboiler,
    offerteZonneboiler: categoryIcons.zonneboiler,
    bewijsMuurisolatie: categoryIcons.muurrenovatie,
    bewijsDakisolatie: categoryIcons.dakrenovatie,
    bewijsVanPvOfZbOpBestaandGebouw: categoryIcons.dakrenovatie,
    keuringsrapportGascondensatieketel: categoryIcons.gascondensatieketel,
    bewijsVentilatie: categoryIcons.buitenschrijnwerkGlas,
    fotosBeglazing: categoryIcons.buitenschrijnwerkGlas,
    technischeFicheEnAttesteringDefenitieveUitschakelingKoelingDoorDeAannemer:
      categoryIcons.warmtepomp,
    fotoVanCollectoren: categoryIcons.zonneboiler,
    fotosVoorEnNaBuitenmuur: categoryIcons.muurrenovatie,
    epcBouwEpCverhuurBuitenmuur: categoryIcons.muurrenovatie,
    planArchitectBuitenmuur: categoryIcons.muurrenovatie,
    betalingsbewijsFacturenBuitenmuur: categoryIcons.muurrenovatie,
    fotosVoorEnNaDak: categoryIcons.dakrenovatie,
    epcBouwEpcVerhuurDak: categoryIcons.dakrenovatie,
    planArchitectDak: categoryIcons.dakrenovatie,
    betalingsbewijsFacturenDak: categoryIcons.dakrenovatie,
    fotosVoorEnNaGascondensatieketel: categoryIcons.gascondensatieketel,
    epcBouwEpcVerhuurGascondensatieketel: categoryIcons.gascondensatieketel,
    betalingsbewijsFacturenGascondensatieketel:
      categoryIcons.gascondensatieketel,
    productlabelGascondensatieketel: categoryIcons.gascondensatieketel,
    fotosVoorEnNaRamenEnDeuren: categoryIcons.buitenschrijnwerkGlas,
    epcBouwEpcVerkoopEnVerhuurRamenEnDeuren:
      categoryIcons.buitenschrijnwerkGlas,
    fotoVanDeAfstandshouderTussenDeGlasbladenMetMarkeringRamenEnDeuren:
      categoryIcons.buitenschrijnwerkGlas,
    planArchitectRamenEnDeuren: categoryIcons.buitenschrijnwerkGlas,
    betalingsbewijsFacturenRamenEnDeuren: categoryIcons.buitenschrijnwerkGlas,
    fotosVoorEnNaVloer: categoryIcons.vloerrenovatie,
    epcBouwEpcVerhuurVloer: categoryIcons.vloerrenovatie,
    planArchitectVloer: categoryIcons.vloerrenovatie,
    betalingsbewijsArchitectenVloer: categoryIcons.vloerrenovatie,
    fotosVoorEnNaWarmtepomp: categoryIcons.warmtepomp,
    epcBouwEpcVerkoopEnVerhuurWarmtepomp: categoryIcons.warmtepomp,
    betalingsbewijsFacturenWarmtepomp: categoryIcons.warmtepomp,
    fotosVoorEnNaWarmtepompboiler: categoryIcons.warmtepompboiler,
    epcBouwEpcVerkoopEnVerhuurWarmtepompboiler: categoryIcons.warmtepompboiler,
    betalingsbewijsFacturenWarmtepompboiler: categoryIcons.warmtepompboiler,
    epcBouwEpcVerkoopEnVerhuurZonneboiler: categoryIcons.zonneboiler,
    betalingsbewijsFacturenZonneboiler: categoryIcons.zonneboiler,
  };

  const icon =
    categoryIconsForAttachmentKeys[
      get(props, ["component", "inputOptions", "name"])
    ];

  return (
    <tr
      className={classnames({
        "vl-data-table__element--error": errorMessages.length > 0,
        "vl-data-table__element--success":
          errorMessages.length === 0 && elements.length > 0,
      })}
    >
      <td data-title={gettext("Attachment to upload")}>
        <div className="attachment-cell">
          <div className="vl-icon-wrapper">
            <span
              className={classnames(
                "vl-icon vl-icon--large vl-icon--light vl-icon--before vl-u-spacer-right--xsmall",
                icon || "wrp-icon-file"
              )}
              aria-hidden="true"
            />
            <InputField {...propsForInputField} shouldUseFullWidth={true} />
          </div>
        </div>
      </td>
      <td data-title={gettext("Upload")}>
        <div className="attachment-cell">
          {" "}
          <AttachmentsList
            {...otherPropsForAttachmentsList}
            id={id}
            elements={elements}
            nested={true}
          />
        </div>
      </td>
    </tr>
  );
}

AttachmentsTableRow.propTypes = attachmentListContainerComponentProps;
