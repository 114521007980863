import React, { useCallback, useContext, useEffect, useState } from "react";
import { map, find } from "lodash";
import PropTypes from "prop-types";

import useChoice, {
  baseComponentProps,
  containerComponentProps,
} from "@skryv/core-react/src/components/form/editor/ChoiceInput/choiceInputHelpers";

import { TranslationsContext } from "@skryv/core-react/src/services/translations";

import EditorComponent from "@skryv/core-react-vo/src/components/form/components/wrappers/EditorComponent/EditorComponent";
import InputField from "@skryv/core-react-vo/src/components/base/InputFields/InputField/InputField";
import Help from "@skryv/core-react-vo/src/components/base/InputFields/InputField/inlines/Help/Help";
import Information from "@skryv/core-react-vo/src/components/base/InputFields/Information/Information";

import "./CommunicationPreference.scss";
import classNames from "classnames";

// statusses
const EBOX_STATUS_LOADING = "ebox_status_loading";
const EBOX_STATUS_ACTIVATED = "ebox_status_activated";
const EBOX_STATUS_NOT_ACTIVATED = "ebox_status_not_activated";

// form paths
const OPTION_EBOX = "eBox";

// eBox info callouts
const eboxStatusInfo = {
  [EBOX_STATUS_LOADING]: {
    id: "eBoxWordtGecontroleerd",
    name: "eBoxWordtGecontroleerd",
    label: "Even geduld...",
    type: "information",
    help: "We kijken na of uw e-box reeds geactiveerd werd",
    helpInLine: true,
    visualisation: "callout-info",
  },
  [EBOX_STATUS_ACTIVATED]: {
    id: "eBoxIsGeavtiveerd",
    name: "eBoxIsGeavtiveerd",
    label: "Uw e-box werd reeds geactiveerd",
    type: "information",
    help: "Uw e-box werd reeds geactiveerd en gekoppeld aan uw persoonlijk e-mailadres. We kunnen u via deze weg op de hoogte houden van de status van uw dossier",
    helpInLine: true,
    visualisation: "callout-success",
  },
  [EBOX_STATUS_NOT_ACTIVATED]: {
    id: "eBoxIsNietGeactiveerd",
    name: "eBoxIsNietGeactiveerd",
    label: "Uw E-BOX werd nog niet geactiveerd",
    type: "information",
    help: 'Om op de hoogte te worden gebracht van uw dossier via uw box, dient u uw e-box te activeren en te koppelen aan uw persoonlijk e-mailadres. <a target="_blank" href="https://www.vlaanderen.be/uw-overheid/mijn-burgerprofiel/digitale-overheidsdocumenten-ebox-raadplegen-in-mijn-burgerprofiel" class="vl-link">Activeer uw e-box hier<span class="vl-u-visually-hidden">(opent in nieuw venster)</span><span class="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external" aria-hidden="true"></span></a>',
    helpInLine: true,
    visualisation: "callout-error",
  },
};

function CommunicationPreferenceContainer({
  manipulator,
  component,
  config,
  ...otherProps
}) {
  const {
    options,
    onChange: coreOnChange,
    ...otherPropsToPass
  } = useChoice(
    {
      manipulator,
      component,
      config,
      ...otherProps,
    },
    EditorComponent
  );
  const [eBoxStatus, setEBoxStatus] = useState(EBOX_STATUS_LOADING);

  useEffect(() => {
    if (manipulator.selectedOption === OPTION_EBOX) checkEBoxStatus();
  }, [manipulator.selectedOption, checkEBoxStatus]);

  const checkEboxStatus = config.customRequest.checkEboxStatus;
  const checkEBoxStatus = useCallback(() => {
    setEBoxStatus(EBOX_STATUS_LOADING);
    checkEboxStatus().then((response) => {
      if (response === true) {
        setEBoxStatus(EBOX_STATUS_ACTIVATED);
      } else if (!response) {
        setEBoxStatus(EBOX_STATUS_NOT_ACTIVATED);
      }
    });
  }, [checkEboxStatus]);

  function onChange(value) {
    coreOnChange(value);
    if (manipulator.selectedOption === OPTION_EBOX) checkEBoxStatus();
  }

  return (
    <CommunicationPreference
      {...otherPropsToPass}
      eBoxStatus={eBoxStatus}
      checkEBoxStatus={checkEBoxStatus}
      onChange={onChange}
      options={options}
    />
  );
}

CommunicationPreferenceContainer.propTypes = containerComponentProps;

export default CommunicationPreferenceContainer;

export function CommunicationPreference({
  eBoxStatus,
  checkEBoxStatus,
  onChange,
  options,
  ...otherPropsToPass
}) {
  const { gettext } = useContext(TranslationsContext);
  const mapOptions = (options) => {
    return map(options, (option) => {
      if (option.name === OPTION_EBOX && eBoxStatus) {
        return {
          ...option,
          // eslint-disable-next-line react/display-name
          nestedFields: () => (
            <div className="editor-component">
              <Information {...eboxStatusInfo[eBoxStatus]} nested={true} />
              {option.name === OPTION_EBOX &&
                eBoxStatus === EBOX_STATUS_NOT_ACTIVATED && (
                  <button
                    className="wrp-check-ebox-again vl-button vl-button--tertiary vl-button--icon-before"
                    onClick={checkEBoxStatus}
                  >
                    <i
                      className="vl-button__icon vl-button__icon--before vl-vi vl-vi-text-undo"
                      aria-hidden="true"
                    ></i>
                    <span className="vl-button__label">
                      {gettext("Check status e-box again")}
                    </span>
                  </button>
                )}
            </div>
          ),
        };
      }
      return option;
    });
  };

  return (
    <CPChoice
      {...otherPropsToPass}
      onChange={onChange}
      options={mapOptions(options)}
    />
  );
}

CommunicationPreference.propTypes = {
  eBoxStatus: PropTypes.oneOf([
    EBOX_STATUS_LOADING,
    EBOX_STATUS_ACTIVATED,
    EBOX_STATUS_NOT_ACTIVATED,
  ]),
  checkEBoxStatus: PropTypes.func,
  onChange: PropTypes.func,
  options: baseComponentProps.options,
};

// This component contains mostly duplicated code from the core platform; except the logic to show the little pill after one of the options
// TODO find a better way to add the pill
function CPChoice({
  id,
  name,
  label,
  options,
  onChange,
  selectedOption,
  isRequired,
  readOnly,
  help,
  helpInLine,
  errorMessages,
  nested,
  warningMessages,
}) {
  const { gettext } = useContext(TranslationsContext);

  const isInError = errorMessages ? errorMessages.length > 0 : false;

  function buildReadOnlyInput() {
    return (
      <div>
        {!selectedOption ? (
          <p className="vl-form__annotation">{gettext("n/a")}</p>
        ) : (
          <div>
            <p className="vl-form__annotation read-only">
              {find(options, (o) => o.name === selectedOption).label}
            </p>
            {options.map(
              (option) => option.nestedFields && option.nestedFields()
            )}
          </div>
        )}
      </div>
    );
  }

  function buildEditableRadioInput() {
    return options.map((option) => (
      <label
        key={option.name}
        className={classNames("vl-radio", "vl-radio--block", {
          "vl-radio--error": isInError,
          "vl-radio--disabled": option.isDisabled,
        })}
        htmlFor={`${id}-radio-choice-${option.name}`}
      >
        <div className="radio-label-wrapper">
          <input
            className={classNames("vl-radio__toggle", {
              "vl-input-field--error": isInError,
            })}
            id={`${id}-radio-choice-${option.name}`}
            value={option.name}
            name={`${name}-radio-choice-${option.name}`}
            type="radio"
            onChange={(event) => onChange(event.target.value)}
            checked={selectedOption === option.name}
            disabled={option.isDisabled}
          />
          <div className="vl-radio__label">
            <span>{option.label}</span>
            {option.name === OPTION_EBOX && !option.isDisabled && (
              <div className="vl-pill">
                <span className="vl-pill__text">
                  <span className="vl-icon vl-icon--before vl-vi vl-vi-lock" />
                  {gettext("safest option")}
                </span>
              </div>
            )}
            {option.help && <Help helpText={option.help} />}
          </div>
        </div>
        {selectedOption === option.name &&
          option.nestedFields &&
          option.nestedFields()}
      </label>
    ));
  }

  return (
    <div className="communication-preference">
      <InputField
        id={id}
        label={label}
        isRequired={isRequired}
        helpInLine={helpInLine}
        help={help}
        errorMessages={errorMessages}
        warningMessages={warningMessages}
        shouldUseFullWidth={nested}
      >
        {readOnly ? buildReadOnlyInput() : buildEditableRadioInput()}
      </InputField>
    </div>
  );
}

CPChoice.propTypes = baseComponentProps;
