export default {
  // milestones : refer to name field in BPMN milestones
  // to be used with getCurrentMilestoneLabel()
  REQUEST_INITIATED_IN_BO: "aanvraagGestartInBO",
  REQUEST_INITIATED: "aanvraagOpgestart",
  FETCH_DATA_CITIZEN: "dataOphalenParticulieren",
  FETCH_DATA_COMPANY: "dataOphalenBedrijven",
  FILLING_IN_REQUEST: "aanvraagInvullen",
  REQUEST_SUBMITTED: "aanvraagIngediend",
  BEROEP_SUBMITTED: "beroepOpgestart",
  BEROEP_STARTED: "beroepsprocedureOpgestart",
  CONTACT_FORM_SUBMITTED: "contactformulierIngevuld",
  CONTACT_DETAILS_CHANGED: "contactgegevensAangepast",
  CALCULATION_ADVICE_STARTED: "berekeningAdviesOpgestart",
  CALCULATION_ADVICE_COMPLETED: "berekeningAdviesVoltooid",
  AUTHENTIC_DATA_STARTED: "authentiekeDataOpgestart",
  AUTHENTIC_DATA_CANCELLED: "authentiekeDataOphalenGeannuleerd",
  REQUEST_CHANGED_AUTHENTIC_DATA: "aanvraagAangepastAuthentiekeData",
  MANUAL_ADRESS_COMPARISON_STARTED: "manueleAdresvergelijkingOpgestart",
  AUTHENTIC_DATA_FETCHED: "authentiekeDataOpgehaald",
  ADDITIONAL_ATTACHMENTS_DELIVERED: "bijkomendeStukkenAangeleverd",
  ADDITIONAL_ATTACHMENTS_DELIVERED_BEROEP: "bijkomendeStukkenAangeleverdBeroep",
  TIMER_ADDITIONAL_ATTACHMENTS: "timerBijkomendeStukken",
  ADDITIONAL_ATTACHMENTS_CANCELED: "ophalenBijkomendeStukkenGeannuleerd",
  ATTEST_CONTROL_STARTED: "attestControleOpgestart",
  ATTEST_CONTROL_SKIPPED: "attestControleNietNodig",
  ADVICE_CONTROL_STARTED: "controleAdviesOpgestart",
  ADVICE_CONTROL_STARTED_BEROEP: "controleAdviesOpgestartBeroep",
  ADVICE_CONTROL_SKIPPED: "controleAdviesNietNodig",
  BULK_ADMIN_CONTROL_STARTED: "bulkValidatieAdministratieveControleOpgestart",
  QUALITY_ADMIN_CONTROL_SKIPPED: "administratieveKwaliteitscontroleNietNodig",
  QUALITY_ADMIN_CONTROL_STARTED: "administratieveKwaliteitscontroleOpgestart",
  TECHNICAL_CONTROL_STARTED: "technischeControleOpgestart",
  TECHNICAL_CONTROL_SKIPPED: "technischeControleNietNodig",
  FINANCIAL_CONTROL_STARTED: "financieleControleOpgestart",
  DOSSIER_TEMP_REJECTED: "dossierVoorlopigGeweigerd",
  DOSSIER_TEMP_APPROVED: "dossierVoorlopigGoedgekeurd",
  DOSSIER_DEF_REJECTED: "dossierDefinitiefGeweigerd",
  DOSSIER_DEF_APPROVED: "dossierDefinitiefGoedgekeurd",
  DOSSIER_DEF_APPROVED_BEROEP: "dossierDefinitiefGoedgekeurdBeroep",
  DOSSIER_DEF_REJECTED_BEROEP: "dossierDefinitiefGeweigerdBeroep",
  BULK_DECISION_CONTROL_STARTED: "bulkValidatieBeslissingOpgestart",
  QUALITY_CONTROL_DECISION_SKIPPED: "kwaliteitscontroleBeslissingNietNodig",
  QUALITY_CONTROL_DECISION_STARTED: "kwaliteitscontroleBeslissingOpgestart",
  BULK_SENDING_DECISION_LETTER: "bulkBeslissingsbriefInWacht",
  DECISION_LETTER_TO_CLIENT: "beslissingNaarKlant",
  DECISION_LETTER_TO_CLIENT_BEROEP: "beslissingNaarKlantBeroep",
  DECISION_LETTER_BEROEP_ONGEGROND_TO_CLIENT: "beroepBeslissingsbriefVerstuurd",
  BEROEP_NOT_VALID: "beroepDefinitiefOngegrond",
  DECISION_LETTER_SENT: "beslissingsbriefVerstuurd",
  DETAIL_DECISION_LETTER_SENT: "detailBeslissingsbriefVerstuurd",
  ADDITIONAL_ATTACHMENTS_REQUESTED: "bijkomendeStukkenOpgevraagd",
  ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP: "bijkomendeStukkenOpgevraagdBeroep",
  MANUAL_REJECTION_STARTED: "manueleWeigeringOpgestart",
  REQUEST_ADAPTED: "aanvraagAangepast",
  REQUEST_DATA_ADAPTED: "aanvraagdatumAangepast",
  AUTHENTIC_DATA_ADAPTED: "authentiekeDataManueelAangepast",
  ADVICE_ADAPTED: "adviesManueelAangepast",
  AUTHENTIC_DATA_RELOADED: "authentiekeDataManueelHerladen",
  CHANGE_OF_REQUESTOR_REQUESTED: "wijzigingAanvragerAangevraagd",
  CHANGE_OF_REQUESTOR_SUBMITTED: "wijzigingAanvragerIngediendDoorKlant",
  GO_BACK_TO_ADVICE: "manueelTerugNaarAdvies",
  GO_BACK_TO_DECISION: "manueelTerugNaarBeslissing",
  COMMUNICATION_TO_REQUESTOR_INITIATED:
    "manueleCommunicatieVerstuurdNaarDeAanvrager",
  UITBETALING_VOLTOOID: "betalingGebeurd",
  UITBETALING_VOLTOOID_BEROEP: "betalingGebeurdBeroep",
  BEROEP_TEGEN_STILZITTEN_INGEDIEND: "beroepTegenStilZittenIngediend",
  BEROEP_ATTEST_CONTROL_NEEDED: "beroepManueelBeslistAttestControleNodig",
  BEROEP_ATTEST_CONTROL_NOT_NEEDED:
    "beroepManueelBeslistAttestControleNietNodig",
  BEROEP_BACK_TO_TECHNICAL_CONTROL: "beroepManueelTerugNaarTechnischeControle",
  BEROEP_BACK_TO_FINANCIAL_CONTROL: "beroepManueelTerugNaarFinancieleControle",
  DOSSIER_REOPENED: "Event_1h6n66s",
  PARENT_REJECTED: "parentAfgekeurd",
};
