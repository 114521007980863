import { createReduxStore } from "@skryv/core-react/src/core/store";

import {
  burgerprofielConfiguration,
  wrpProfile,
  search,
  statusPage,
  featureToggles,
} from "./reducers";

import apiMiddleware from "@skryv/core-react/src/store/api";

export default createReduxStore(
  {
    burgerprofielConfiguration,
    wrpProfile,
    search,
    statusPage,
    featureToggles,
  },
  undefined,
  [apiMiddleware]
);
